<template>
  <!-- Confirmation modal for unliking the list -->

  <div :class="gridClass">
    <img
      v-if="displayedImages.length > 0"
      v-for="(image, index) in displayedImages"
      :key="index"
      :src="image.url"
      :alt="image.altText"
      :style="imageStyle"
      class="product-image"
    />
    <div v-if="displayedImages.length === 0" class="empty-list-text">
      Listan är tom
    </div>
  </div>
</template>

<script>
export default {
  name: "ListCard",
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Determines which product images to display.
     * Limits to a maximum of 9 images.
     * @returns {Array} An array of image objects.
     */
    displayedImages() {
      return this.list.products
        .slice(0, 9)
        .map((product) => product.product.mainImage);
    },
    /**
     * Calculates the appropriate class for the grid based on the number of images.
     * This approach ensures that the layout dynamically adapts to the number of products.
     * @returns {String} The grid class name.
     */
    gridClass() {
      const count = this.displayedImages.length;
      return count === 0
        ? "image-grid-empty"
        : count === 1
        ? "image-grid-one-row"
        : count === 2
        ? "image-grid-two-products"
        : count <= 3
        ? "image-grid-three-rows-one-col"
        : count <= 5
        ? "image-grid-two-rows"
        : "image-grid-three-rows";
    },
    /**
     * Calculates the appropriate style for the images based on the number of images.
     * This approach ensures that the images dynamically adapt to the available space.
     * @returns {Object} The style object.
     */
    imageStyle() {
      const count = this.displayedImages.length;
      return count === 1
        ? { width: "100%", height: "75%", margin: "5px" }
        : count === 2
        ? { width: "calc(50% - 10px)", height: "60%", margin: "5px" }
        : count <= 3
        ? { width: "100%", height: "30%", margin: "5px" }
        : count <= 6
        ? {
            width: "calc(50% - 10px)",
            height: "calc(33% - 10px)",
            margin: "5px",
          }
        : {
            width: "calc(33.33% - 10px)",
            height: "calc(33.33% - 10px)",
            margin: "5px",
          };
    },
  },
};
</script>

<style scoped>
.list-card {
  max-width: 200px;
  min-width: 170px;
}
.image-grid-empty,
.image-grid-one-row,
.image-grid-two-products,
.image-grid-two-rows,
.image-grid-three-rows,
.image-grid-three-rows-one-col {
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.image-grid-two-products {
  flex-direction: row;
}

.image-grid-two-rows,
.image-grid-three-rows {
  height: 200px;
}

.product-image {
  object-fit: contain;
  border-radius: 5px;
}

.list-title {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.empty-list-text {
  font-size: 16px;
  color: grey;
  text-align: center;
}

.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: red;
  cursor: pointer;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.modal-title {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: normal;
}

.modal-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.modal-btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-btn.cancel {
  background-color: #003a70;
  color: white;
}

.modal-btn.cancel:hover {
  background-color: #005092;
}

.modal-btn.delete {
  background-color: #c94631;
  color: white;
}

.modal-btn.delete:hover {
  background-color: darkred;
}

/* Responsive Layout */
@media (max-width: 1200px) {
  .list-card {
    min-width: calc(33.33% - 20px);
  }
}

@media (max-width: 992px) {
  .list-card {
    min-width: calc(50% - 20px);
  }
}

@media (max-width: 575px) {
  .list-card {
    min-width: calc(100% - 20px);
  }
}
</style>
