<template>
  <v-col align="center">
    <v-row justify="center" class="mt-10">
      <v-col align="center" cols="12" sm="7">
        <p class="bold-h1">{{ $t("how_to_become_a_member") }}</p>
      </v-col>
    </v-row>
    <v-row justify="space-evenly" class="mt-8">
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">01</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_1") }}</p>
        <p class="body-2 mt-2">
          {{ $t("top_up_account") }}
        </p>
      </v-col>
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">02</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_2") }}</p>
        <p class="body-2 mt-2">
          {{ $t("shop_with_balance") }}
        </p>
      </v-col>
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">03</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_3") }}</p>
        <p class="body-2 mt-2">
          {{ $t("monthly_bonuses") }}
        </p>
      </v-col>
    </v-row>
  </v-col>
  <v-col align="center" class="mt-10">
    <p class="bold-h1">{{ $t("coming_soon") }}...</p>
    <p class="body-2 mt-4">{{ $t("feature_not_released") }}</p>
    <p class="body-2">
      {{ $t("form_prompt") }}
    </p>
    <v-col cols="12" md="6" xl="4">
      <v-form v-model="form" @submit.prevent="onSubmit" ref="form">
        <v-text-field
          id="email"
          class="mt-2"
          v-model="email"
          validate-on="submit"
          :label="$t('what_is_your_email')"
          persistent-hint
          type="email"
          color="primary"
          bg-color="surface"
          variant="outlined"
          single-line
          :rules="emailRules"
        />
        <v-btn
          class="pa-7 button text-none"
          block
          elevation="0"
          type="submit"
          color="primary"
          >{{ $t("join_waitlist") }}</v-btn
        >
      </v-form>
    </v-col>
  </v-col>
  <v-col
    style="background-color: rgba(138, 183, 233, 0.7)"
    class="pl-8 pr-8 mt-14"
  >
    <p class="bold-h3">{{ $t("what_are_the_terms_for_the_membership") }}</p>
    <v-col>
      <ul class="body-2">
        <li>
          {{ $t("terms_description") }}
        </li>
        <li class="mt-4">
          {{ $t("bonus_details") }}
        </li>
        <li class="mt-4">
          {{ $t("please_see_our") }}
          <router-link
            :to="{ name: 'userterms' }"
            class="pr-1"
            style="color: black"
            >{{ $t("terms_of_service") }}</router-link
          >
          {{ $t("full_terms_and_conditions_info") }}
        </li>
      </ul>
    </v-col>
  </v-col>
</template>
<script>
import bag2 from "../assets/images/bag.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import UserAPI from "@/api/UserAPI"; // Import the UserAPI
export default {
  data() {
    return {
      bag2: bag2,
      food_half: food_half,
      star: star,
      email: "",
      message: "",
      form: false,
      emailRules: [
        (value) => {
          if (value) return true;

          return "Ange en e-postadress";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return "E-postadressen är inte giltig.";
        },
      ],
    };
  },
  methods: {
    async onSubmit() {
      this.message = "";
      const { valid } = await this.$refs.form.validate();

      if (!valid) return;
      await UserAPI.joinWaitlist(this.email);
      this.email = "";
      this.$store.dispatch("vtoast/showToast", {
        message: "Vi har mottagit ditt svar.",
        color: "success",
      });
    },
  },
  async mounted() {},
};
</script>
