<template>
  <v-dialog max-width="500" v-model="show" @click:outside="decline">
    <v-card :title="$t('open_lockers')" class="bold-h2">
      <v-window disabled v-model="step">
        <v-window-item disabled :value="1">
          <v-card-text class="body-1">
            <v-col align="center" justify="center">
              <p class="body-2">
                {{ $t("are_you_sure_you_want_to_open_lockers") }}
              </p>
              <p class="bold-h1">
                {{
                  $t("locker_count", {
                    count: order.lockerOrder.lockerBoxes.length,
                  })
                }}
              </p>
              <p
                v-if="order.lockerOrder.lockerBoxes.length > 1"
                class="bold-1"
                style="color: #c94631"
              >
                {{ $t("more_than_one_locker") }}
              </p>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="decline" class="button text-none">{{
              $t("no")
            }}</v-btn>
            <v-btn
              :loading="dialogIsLoading"
              @click="collectOrder"
              class="button text-none"
              >{{ $t("yes") }}</v-btn
            >
          </v-card-actions></v-window-item
        >
        <v-window-item disabled :value="2">
          <v-card-text>
            <v-col v-if="order.lockerOrder != undefined">
              <div class="bold-h3">
                <span>{{ $t("locker") + ": " }}</span>
                <span
                  v-for="(box, index) in order.lockerOrder.lockerBoxes"
                  :key="box.nr"
                  class="locker-box"
                >
                  {{
                    box.nr +
                    (index + 1 < order.lockerOrder.lockerBoxes.length
                      ? ", "
                      : "")
                  }}
                </span>
              </div>
              <p class="bold-h3 pt-4">{{ $t("is_now_open") }}</p>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="dialogIsLoading"
              @click="decline"
              class="button text-none"
              >{{ $t("ok") }}</v-btn
            >
          </v-card-actions></v-window-item
        ></v-window
      >
    </v-card>
  </v-dialog>
</template>
<script>
import OrderAPI from "@/api/OrderAPI";
export default {
  data() {
    return {
      dialog: false,
      dialogIsLoading: false,
      step: 1,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    decline() {
      this.show = false;
      this.step = 1;
    },
    async collectOrder() {
      try {
        this.dialogIsLoading = true;
        await OrderAPI.collectOrder(this.order._id);
        this.dialogIsLoading = false;
        this.step = 2;
      } catch (error) {
        this.dialogIsLoading = false;
      }
    },
  },
};
</script>
