<template>
  <v-snackbar
    :color="toastColor"
    :timeout="toastTimer"
    v-model="toastVisible"
    :location="location"
  >
    <v-icon v-if="toastIcon !== undefined" class="mr-2">{{ toastIcon }}</v-icon
    >{{ toastMessage }}
    <template v-slot:actions>
      <v-btn
        color="white"
        icon="mdi-close"
        variant="text"
        size="small"
        @click.stop="closeSnackbar"
        aria-label="Stäng"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "vtoast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      location: "top",
      timer: 3000,
    };
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch("vtoast/hideToast");
    },
  },
  computed: {
    ...mapGetters("vtoast", [
      "isToastVisible",
      "toastMessage",
      "toastColor",
      "toastTimer",
      "toastIcon",
    ]),
    toastVisible: {
      get() {
        return this.isToastVisible;
      },
      set(value) {
        if (!value) {
          this.$store.dispatch("vtoast/hideToast");
        }
      },
    },
  },
};
</script>
