<template>
  <v-col align="center">
    <p class="bold-h1">{{ $t("customer_service") }}</p>
    <p class="mt-2 body-1">{{ $t("customer_experience_feedback") }}</p>
    <v-row justify="center" class="mt-15">
      <v-col cols="12" md="4">
        <v-icon size="40">mdi-map-marker</v-icon>
        <p class="bold-h3">
          {{ $t("address") }}
        </p>
        <p>Bjälbogatan 2</p>
        <p>582 47 Linköping</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-icon size="40">mdi-phone</v-icon>
        <p class="bold-h3">
          {{ $t("phone") }}
        </p>
        <a
          style="color: black; text-decoration: underline"
          href="tel:46705711922"
          >+46705711922</a
        >
      </v-col>
      <v-col cols="12" md="4">
        <v-icon size="40">mdi-email</v-icon>
        <p class="bold-h3">
          {{ $t("email") }}
        </p>
        <a
          style="color: black; text-decoration: underline"
          v-bind:href="`mailto:info@nearbystore.se`"
          >info@nearbystore.se</a
        >
      </v-col>
    </v-row>
    <p class="bold-h2 mt-15">
      {{ $t("curious_about_more_investor_or_looking_for_job") }}
    </p>

    <v-btn
      class="mt-4"
      flat
      color="primary"
      size="x-large"
      @click="goToWebsite('https://nearbygroup.se/')"
      >{{ $t("visit_nearbygroup") }}</v-btn
    >
  </v-col>
</template>
<script>
import bag from "../assets/images/bag.png";
export default {
  data() {
    return {
      bag: bag,
    };
  },
  methods: {
    goToWebsite(url) {
      window.open(url, "_blank");
    },
  },
  async mounted() {},
};
</script>
