<template>
  <v-col algin="center" class="pa-0">
    <v-card
      v-if="
        recipeId != ''
          ? recipeInCart()
          : isInCart(cartProduct ? product.productVariant._id : product._id)
      "
      color="primary"
      height="33"
      elevation="0"
    >
      <v-row class="pa-4" justify="center" align="center" cols="12">
        <v-col class="align-stretch pa-0" justify="center" align="center">
          <v-icon size="small" @click.stop="handleRemoveFromCart()"
            >mdi-minus</v-icon
          >
        </v-col>
        <v-col
          class="align-stretch pa-0"
          align="center"
          justify="center"
          sm="4"
        >
          <input
            aria-controls="none"
            class="input button pa-0"
            @focus="$event.target.select()"
            type="text"
            v-model="quantity"
            @blur="input"
            v-on:keyup.enter="$event.target.blur()"
            color="white"
          />
        </v-col>
        <v-col class="align-stretch pa-0" justify="center" align="center">
          <v-icon size="small" @click.stop="handleAddToCart()">mdi-plus</v-icon>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-else
      elevation="0"
      color="primary"
      class="text-none pa-0"
      block
      height="33"
      @click.stop="addToCart()"
    >
      <v-row class="pa-4" justify="center" align="center" cols="12">
        <p class="button">{{ $t("add") }}</p></v-row
      >
    </v-card></v-col
  >
</template>
<script>
import { mapActions, mapState } from "vuex";
import DiscountIcon from "../assets/svg/discount.vue";

export default {
  name: "ProductButton",
  components: {
    DiscountIcon,
  },
  data() {
    return {
      quantity: "0",
      debounceTimer: null,
      debounceDelay: 700, // 1 second delay for debouncing actions
      localShoppingQuantity: this.shoppingQuantity,
    };
  },
  props: {
    product: {
      required: true,
    },
    cartProduct: {
      default: false,
      type: Boolean,
    },
    recipeId: {
      default: "",
      type: String,
    },
    shoppingId: {
      default: "",
      type: String,
    },
    shoppingQuantity: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    ...mapState(["localCart", "cart"]),
    specificItemId() {
      // Replace 1 with the index or condition to find your specific object
      const variant = this.localCart.items.find((v) => v.id === this.getId());
      this.quantity = this.getQuantity().toString();
      return variant ? variant : null;
    },
    cartQuantity() {
      const id = this.getId();
      if (this.recipeId != "") {
        const recipe = this.cart.recipes.find((r) => r.recipe == this.recipeId);

        const product = recipe.products.find((v) => v.productId === id);
        return product.quantity != null ? product.quantity : 0;
      }
      if (this.cartProduct && this.shoppingId != "") {
        const variant = this.cart.productVariants.find(
          (v) => v.productVariant._id === id
        );

        return variant ? variant.shoppingListQuantity : 0;
      }
      const variant = this.cart.productVariants.find(
        (v) => v.productVariant._id === id
      );

      return variant ? variant.quantity : 0;
    },
    localCartQuantity() {
      const id = this.getId();
      if (this.recipeId != "") {
        const recipe = this.localCart.recipes.find(
          (r) => r.recipe == this.recipeId
        );

        const product = recipe.products.find((v) => v.productId === id);
        return product.quantity != null ? product.quantity : 0;
      }
      if (this.cartProduct && this.shoppingId != "") {
        const shoppinglist = this.localCart.shoppingLists.find(
          (r) => r.shoppingList == this.shoppingId
        );

        const product = shoppinglist.products.find((v) => v.productId === id);
        return product.quantity != null ? product.quantity : 0;
      }
      const variant = this.localCart.items.find((v) => v.id === id);

      return variant ? variant.quantity : 0;
    },
  },
  mounted() {
    this.quantity = this.getQuantity().toString();
  },
  watch: {
    specificItemId(newCart, oldCart) {
      this.quantity = this.getQuantity().toString();
    },
    quantity(newCount, oldCount) {
      // Remove non-numeric characters

      this.quantity = this.quantity.replace(/[^0-9]/g, "");
      if (this.quantity === "") {
        this.quantity = "0";
      }
      // Remove leading zeros (unless the value is just '0')
      if (this.quantity.length > 1 && this.quantity.startsWith("0")) {
        this.quantity = this.quantity.replace(/^0+/, "");
      }
      if (this.quantity.length > 3) {
        this.quantity = this.quantity.substring(0, 3);
      }

      return this.quantity;
    },
  },

  methods: {
    input() {
      this.quantity = this.quantity.toString();

      this.changeProduct(parseInt(this.quantity));
    },
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "changeLocalCart",
      "changeCart",
    ]),
    isInCart(id) {
      return this.localCart.items.some((variant) => variant.id === id);
    },
    getId() {
      return this.cartProduct
        ? this.product.productVariant._id
        : this.product._id;
    },
    recipeInCart() {
      const id = this.getId();
      return this.localCart.recipes
        .find((e) => e.recipe == this.recipeId)
        .products.some((variant) => variant.productId === id);
    },
    shoppingInLocalCart() {
      const id = this.getId();

      return this.shoppingId
        ? this.localCart.shoppingLists
            .find((e) => e.shoppingList == this.shoppingId)
            .products.some((variant) => variant.productId === id)
        : false;
    },
    shoppingInCart() {
      const id = this.getId();

      return this.shoppingId
        ? this.cart.shoppingLists
            .find((e) => e.shoppingList == this.shoppingId)
            .products.some((variant) => variant.productId === id)
        : false;
    },
    changeProduct(quantity) {
      this.changeLocalCart({
        id: this.getId(),
        recipeId: this.recipeId,
        shoppingId: this.shoppingId,
        quantity: quantity - this.localCartQuantity,
      });

      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(async () => {
        this.changeCart({
          id: this.getId(),
          recipeId: this.recipeId,
          shoppingId: this.shoppingId,
          quantity: quantity - this.cartQuantity,
        });
      }, this.debounceDelay);
    },

    handleProductClick(product) {
      this.replace == true
        ? this.$router.replace({
            name: "ProductDetail",
            params: { id: product._id },
          })
        : this.$router.push({
            name: "ProductDetail",
            params: { id: product._id },
          });
    },
    addToCart() {
      this.quantity =
        this.shoppingQuantity > 0
          ? this.shoppingQuantity.toString()
          : (parseInt(this.quantity) + 1).toString();

      this.changeProduct(parseInt(this.quantity));
    },
    handleAddToCart() {
      this.quantity = (parseInt(this.quantity) + 1).toString();

      this.changeProduct(parseInt(this.quantity));
    },
    handleRemoveFromCart() {
      this.quantity = (parseInt(this.quantity) - 1).toString();

      this.changeProduct(parseInt(this.quantity));
    },
    getQuantity() {
      if (this.cartProduct) {
        if (this.recipeId != "") {
          const recipe = this.localCart.recipes.find(
            (r) => r.recipe == this.recipeId
          );
          const product = recipe.products.find(
            (r) => r.productId == this.getId()
          );

          return product != null ? product.quantity : 0;
        }
        if (this.shoppingId != "") {
          const shoppingList = this.localCart.shoppingLists.find(
            (r) => r.shoppingList == this.shoppingId
          );

          const product = shoppingList.products.find(
            (r) => r.productId == this.getId()
          );

          return product != null ? product.quantity : 0;
        }
      }

      const id = this.getId();
      const variant = this.localCart.items.find((v) => v.id === id);

      return variant ? variant.quantity : 0;
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input {
  width: 100%;
  color: white;
  border: 1px solid transparent;
  text-align: center;
}
.input:focus {
  outline: none !important;
  border: 1px solid white;
  border-radius: 2px;
}
.hover {
  cursor: pointer;
}
</style>
