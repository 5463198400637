<template>
  <div class="category-navigation-wrapper">
    <div class="navigation-header">
      <h2 class="bold-h2">{{ $t("categories") }}</h2>
    </div>
    <div class="category-navigation-mobile">
      <div
        v-for="category in parentCategories"
        :key="category._id"
        class="category-circle"
        @click="navigateToCategory(category._id)"
      >
        <img
          :src="category.logo.url"
          :alt="category.name"
          class="category-icon"
          v-if="category.logo.url"
        />
        <v-icon v-else class="default-icon">mdi-folder</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CategoryNavigationMobile",
  /**
   * Sets up router for navigating pages.
   * @return {Object} Router instance.
   */
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapGetters(["categories"]),

    /**
     * Computes parent categories.
     * @return {Array} Parent categories.
     */
    parentCategories() {
      return this.categories.filter((cat) => !cat.parent);
    },
  },
  methods: {
    /**
     * Navigates to a category page.
     * @param {String} categoryId - The ID of the category.
     */
    navigateToCategory(categoryId) {
      this.router.push({
        name: "category",
        params: {
          categoryName: categoryId,
        },
      });
    },
  },
};
</script>

<style scoped>
.category-navigation-wrapper {
  width: 95%; /* Parent container width */
  margin: 0 auto; /* Center the parent container */
  margin-top: 50px;
}

.navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.navigation-title {
  color: black;
  padding: 5px;
  font-weight: normal;
  font-size: 32px;
  z-index: 1;
}

.navigation-link {
  color: #003a70;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: regular;
  font-size: 25px;
  text-decoration: underline;
}

.category-navigation-mobile {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 10px;
  gap: 10px;
  background-color: transparent; /* Transparent background */
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.category-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px; /* Imperfect width to clip the next category */
  height: 75px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.category-icon {
  width: 40px;
  height: 40px;
}

.default-icon {
  color: #003a70; /* Default icon color */
}
</style>
