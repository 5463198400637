<template>
  <v-container fluid>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>

    <v-col align="start" justify="start" v-else>
      <Breadcrumbs :items="breadcrumbItems" />
      <v-row class="mt-5">
        <v-col cols="12" :md="mainContentWidth < 800 ? '12' : '5'">
          <v-card class="pa-4 sticky-card" elevation="0">
            <v-text-field
              v-if="isEditing && isUser"
              v-model="list.name"
              :disabled="!isEditing"
              label="Editable Text"
              :append-icon="isEditing ? 'mdi-check' : 'mdi-pencil'"
              @click:append="changeName"
              @keydown.enter="changeName"
              color="primary"
              bg-color="surface"
              variant="outlined"
              single-line
              class="bold-h4"
            ></v-text-field>
            <v-row v-else class="pa-2" justify="space-between">
              <p class="bold-h1">{{ list.name }}</p>
              <v-btn
                v-if="isUser"
                icon="mdi-pencil"
                color="primary"
                @click="isEditing = !isEditing"
              ></v-btn
            ></v-row>

            <p class="body-1 mt-2">
              {{
                $t("items_count", { count: list.activeProducts.length }) +
                " | " +
                $t("products_count", {
                  count: list.activeProducts.reduce((accumulator, item) => {
                    return accumulator + item.quantity;
                  }, 0),
                })
              }}
            </p>
            <v-row class="pa-2 mt-2">
              <v-btn
                @click="addListToCart()"
                color="primary"
                class="button text-none mr-2 mt-2"
                flat
                >{{ $t("add_to_cart") }}</v-btn
              >

              <v-btn
                @click="removeListFromCart()"
                v-if="shoppingListInCart"
                color="error"
                class="button text-none mt-2"
                flat
                >{{ $t("remove_from_cart") }}</v-btn
              >
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" :md="mainContentWidth < 800 ? '12' : '7'">
          <v-card elevation="0" v-if="isUser">
            <v-col>
              <p class="bold-h3 pb-4">{{ $t("add_multiple_items_to_list") }}</p>
              <v-autocomplete
                v-model="tag"
                :items="products"
                :loading="loadingProducts"
                v-model:search="searchInput"
                prepend-inner-icon="mdi-magnify"
                menu-icon=""
                color="primary"
                bg-color="surface"
                variant="outlined"
                single-line
                item-value="_id"
                item-title="name"
                hide-selected
                hide-no-data
                :label="$t('search_item') + '...'"
              >
                <template v-slot:item="{ props, item }">
                  <v-card :key="item.raw._id" class="pa-2 ma-2" elevation="0">
                    <v-row class="pa-2">
                      <v-col
                        align="center"
                        justify="center"
                        cols="2"
                        style="position: relative"
                        class="pa-2"
                      >
                        <DiscountPrice
                          v-if="item.raw.promotion"
                          style="
                            position: absolute;
                            top: 3px;
                            right: 0px;
                            z-index: 1000;
                          "
                          :size="
                            !$vuetify.display.xs
                              ? { width: 60, height: 60 }
                              : { width: 45, height: 45 }
                          "
                          :promotion="item.raw.promotion"
                          :isWeight="item.raw.weightProduct"
                        />
                        <v-img
                          :src="item.raw.thumbnail.url"
                          alt="Product Image"
                          class="hover"
                          height="60"
                          @click="handleProductClick(item.raw)"
                        />
                      </v-col>
                      <v-col align="space-between" class="pa-2 pr-4 pt-4">
                        <v-row class="pl-3 pr-3 pt-1">
                          <p class="bold-1">
                            {{ item.raw.name }}
                          </p>
                        </v-row>
                        <v-row class="pl-3 pr-3 mt-1" align="end">
                          <v-col class="pa-0" justify="center">
                            <v-col sm="12" class="pa-0 pr-1">
                              <p class="body-3">
                                {{
                                  item.raw.manufacturer +
                                  " " +
                                  item.raw.displayWeight
                                }}
                              </p></v-col
                            ></v-col
                          >
                          <v-col
                            class="pa-0 pl-1 pt-2"
                            cols="5"
                            sm="4"
                            align="end"
                          >
                          </v-col>
                        </v-row>

                        <p
                          class="bold-h3 mt-4"
                          style="color: #c94631"
                          v-if="
                            item.raw.promotion != null &&
                            item.raw.promotion.minQuantity == 1
                          "
                        >
                          <span>
                            {{
                              item.raw.promotion.discountValue.toFixed(2)
                            }}</span
                          >
                          {{
                            "/" +
                            (item.raw.weightProduct
                              ? "kg"
                              : $t("piece_abbreviation"))
                          }}
                        </p>

                        <p class="bold-h4 mt-4" v-else>
                          <span> {{ item.raw.price }}</span>
                          {{
                            "/" +
                            (item.raw.weightProduct
                              ? "kg"
                              : $t("piece_abbreviation"))
                          }}
                        </p>
                      </v-col>
                      <v-col align="end" justify="end">
                        <v-btn
                          :color="
                            productIsInList(item.raw._id) ? 'error' : 'primary'
                          "
                          flat
                          class="text-none button"
                          @click="addRemoveProduct(item.raw)"
                          >{{
                            productIsInList(item.raw._id)
                              ? $t("remove")
                              : $t("add")
                          }}</v-btn
                        >
                      </v-col>
                      <v-divider :thickness="2"></v-divider>
                    </v-row>
                  </v-card>
                </template>
              </v-autocomplete>
            </v-col>
          </v-card>
          <p class="bold-h2">Produkter</p>
          <v-row justify="center">
            <v-col cols="12" md="12" justify="center">
              <v-card
                v-for="(p, index) in list.activeProducts"
                :key="p.product._id"
                class="pa-2 ma-2"
                elevation="0"
              >
                <v-row>
                  <div
                    v-if="isUser"
                    class="ml-4 mt-4 mb-4 pa-1 quantityButton"
                    style="border-radius: 5px; background-color: #6786b8"
                  >
                    <v-icon
                      @click="changeQuantityListProduct(p, ++p.quantity)"
                      color="surface"
                      >mdi-chevron-up</v-icon
                    >

                    <p class="bold-h4 pb-1" style="color: white">
                      {{ p.quantity }}
                    </p>

                    <v-icon
                      @click="changeQuantityListProduct(p, --p.quantity)"
                      color="surface"
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                  <v-col
                    align="center"
                    justify="center"
                    cols="2"
                    style="position: relative"
                    class="pa-2"
                  >
                    <DiscountPrice
                      v-if="p.product.promotion"
                      style="
                        position: absolute;
                        top: 3px;
                        right: 0px;
                        z-index: 1000;
                      "
                      :size="
                        !$vuetify.display.xs
                          ? { width: 60, height: 60 }
                          : { width: 45, height: 45 }
                      "
                      :promotion="p.product.promotion"
                      :isWeight="p.product.weightProduct"
                    />
                    <v-img
                      :src="p.product.thumbnail.url"
                      alt="Product Image"
                      class="hover"
                      height="100"
                      @click="handleProductClick(p.product)"
                    />
                  </v-col>
                  <v-col align="space-between" class="pa-2 pr-4 pt-4">
                    <v-row class="pl-3 pr-3 pt-1">
                      <p class="bold-1">
                        {{ p.product.name }}
                      </p>
                    </v-row>
                    <v-row class="pl-3 pr-3 mt-1" align="end">
                      <v-col class="pa-0" justify="center">
                        <v-col sm="12" class="pa-0 pr-1">
                          <p class="body-3">
                            {{
                              p.product.manufacturer +
                              " " +
                              p.product.displayWeight
                            }}
                          </p></v-col
                        ></v-col
                      >
                      <v-col class="pa-0 pl-1 pt-2" cols="5" sm="5" align="end">
                        <ProductButton
                          :product="p.product"
                          :shoppingQuantity="p.quantity"
                          :shoppingId="shoppingListInCart ? list._id : ''"
                        />
                      </v-col>
                    </v-row>

                    <p
                      class="bold-h3 mt-4"
                      style="color: #c94631"
                      v-if="
                        p.product.promotion != null &&
                        p.product.promotion.minQuantity == 1
                      "
                    >
                      <span>
                        {{ p.product.promotion.discountValue.toFixed(2) }}</span
                      >
                      {{
                        "/" +
                        (p.product.weightProduct
                          ? "kg"
                          : $t("piece_abbreviation"))
                      }}
                    </p>

                    <p class="bold-h4 mt-4" v-else>
                      <span> {{ p.product.price }}</span>
                      {{
                        "/" +
                        (p.product.weightProduct
                          ? "kg"
                          : $t("piece_abbreviation"))
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <RemoveDialog
            :visible="removeDialog"
            @close="removeDialog = false"
            :loading="removeLoading"
            :title="$t('remove_list') + '?'"
            @remove="removeList"
            :body="$t('irreversible_action')"
          />
          <v-btn
            v-if="user"
            class="mt-4 button text-none"
            variant="outlined"
            color="error"
            @click="removeDialog = true"
            >{{ $t("remove_list") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatTime, getDayLabel } from "../utils.js";
import ProductButton from "../components/ProductButton.vue";
import ShoppingListAPI from "@/api/SaveAPI.js";
import CartAPI from "@/api/CartAPI.js";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import { useI18n } from "vue-i18n";
import RemoveDialog from "@/components/RemoveDialog.vue";
import { useHead } from "@unhead/vue";
export default {
  name: "ListDetail",
  components: {
    RemoveDialog,
    Header,
    ProductButton,
    Breadcrumbs,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      removeLoading: false,
      removeDialog: false,
      isEditing: false,
      loading: true,
      loadingProducts: false,
      list: {},
      products: [],
      searchInput: null,
      tag: null,
      debounceTimer: null,
      debounceDelay: 700, // 1 second delay for debouncing actions
    };
  },
  watch: {
    searchInput(val) {
      if (val != undefined && val != "") {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(async () => {
          this.tag = val;
          this.fetchProducts(val);
        }, this.debounceDelay);
      } else {
        clearTimeout(this.debounceTimer);
        this.products = [];
      }
    },
  },
  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
      "mainContentWidth",
    ]),
    shoppingListInCart() {
      return (
        this.cart.shoppingLists.findIndex(
          (e) => e.shoppingList == this.list._id
        ) != -1
      );
    },
    isUser() {
      return this.user != null && this.list.userId == this.user._id;
    },
    breadcrumbItems() {
      // Initialize breadcrumbs with the home page
      let items = [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        ...(this.user != null && this.user._id == this.list.userId
          ? [
              {
                text: this.t("saved"),
                to: {
                  name: "saved",
                },
              },
            ]
          : []),

        {
          text: this.list.name,
          disabled: true,
        },
      ];
      return items;
    },
  },
  activated() {
    this.fetchShoppingList();
  },
  async mounted() {
    this.fetchShoppingList();
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
      "addShoppingListCart",
      "removeShoppingListCart",
    ]),
    async changeName() {
      this.isEditing = false;
      await ShoppingListAPI.updateShoppingList(this.list._id, {
        name: this.list.name,
      });
    },
    async fetchProducts(search) {
      try {
        this.loadingProducts = true;

        const response = await CategoryProductAPI.getProducts({
          searchString: search,
          page: this.page,
        });
        this.products = response.data;
        this.loadingProducts = false;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    handleProductClick(product) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: product._id },
      });
    },
    async removeListFromCart() {
      await this.removeShoppingListCart({ shoppingId: this.list._id });
    },
    async addListToCart() {
      const products = [];
      this.list.activeProducts.forEach((item) => {
        // Use Array.find to find the store object with storeID "1"

        products.push({
          productId: item.product._id,
          quantity: item.quantity,
        });
      });
      const shoppingList = {
        name: this.list.name,
        products: products,
        shoppingList: this.list._id,
      };

      await this.addShoppingListCart({ shoppingList: shoppingList });
    },
    async changeQuantityListProduct() {
      try {
        await ShoppingListAPI.updateShoppingList(this.list._id, {
          name: this.list.name,
          products: this.list.activeProducts
            .map((p) => ({
              product: p.product._id,
              quantity: p.quantity,
            }))
            .concat(
              this.list.notActiveProducts.map((p) => ({
                product: p.product._id,
                quantity: p.quantity,
              }))
            ), // Ensure only product IDs are sent
          onlineAvailability: this.list.onlineAvailability,
        });
        this.setHead();
      } catch (error) {
        console.error("Error adding product to list:", error);
      }
    },
    async fetchShoppingList() {
      try {
        this.loading = true;
        const response = await ShoppingListAPI.getShoppingListById(
          this.$route.params.id
        );
        this.list = response.data;
        this.setHead();
        this.loading = false;
      } catch (error) {
        console.error("Error loading shopping lists:", error);
      } finally {
      }
    },
    productIsInList(id) {
      return (
        this.list.activeProducts.findIndex((p) => p.product._id == id) != -1
      );
    },
    addRemoveProduct(product) {
      const pIndex = this.list.activeProducts.findIndex(
        (p) => p.product._id == product._id
      );
      if (pIndex != -1) {
        this.list.activeProducts.splice(pIndex, 1);
      } else {
        this.list.activeProducts.push({ product: product, quantity: 1 });
      }
      this.changeQuantityListProduct();
    },
    async removeList(closeDialog) {
      this.removeLoading = true;
      if (this.shoppingListInCart) {
        await this.removeListFromCart();
      }

      await ShoppingListAPI.deleteShoppingList(this.list._id);
      this.removeLoading = false;
      closeDialog();
      this.$router.push({ name: "saved" });
    },
    setHead() {
      const listTitle = `${this.list.name} | Listor - NearbyStore`;

      useHead({
        title: listTitle,

        meta: [
          {
            property: "og:url",
            content: window.location.href, // Automatically use current page URL
          },
          {
            property: "og:title",
            content: listTitle, // Ensure Open Graph title matches
          },
          {
            hid: "description",
            name: "description",
            content:
              "Beställ idag på NearbyStore och få din mat levererad samma dag. Upptäck ett brett sortiment av färska råvaror och livsmedel, med bekväm hämtning nära dig. Shoppa enkelt och snabbt!",
          },
        ],
        __key: "listDetailHead",
      });
    },
  },
};
</script>
<style scoped>
.sticky-card {
  position: sticky;
  top: 175px;
}
.quantityButton {
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Arrange items in a column */
  align-items: center;
  justify-content: space-between; /* Distribute space between items */
}
</style>
