/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
const myTheme = {
  dark: false,
  colors: {
    background: "#ECECEC",
    surface: "#F5F5F9",
    primary: "#003A70",
    "primary-darken-1": "#99B0C6",
    secondary: "#8AB7E9",
    "secondary-darken-1": "#D0E2F6",
    third: "#6786B8",
    "third-darken-1": "#C2CFE3",
    fourth: "#0082CA",
    "fourth-darken-1": "#97CEEC",
    fontColor: "#222222",
    error: "#C94631",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: "myTheme",
    themes: {
      myTheme,
    },
  },
});
