<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>NearbyStores Integritetspolicy</v-card-title>
          <v-card-text>
            <p>
              NearbyStore Sverige AB (“NearbyStore”, “vi”, “våra”) behandlar
              personuppgifter för användare av våra Tjänster och
              livsmedelsförsäljning via vår App och Beställningsskärm och för
              besökare av Webbsidan nearbystore.se.
            </p>
            <br />
            <p>
              Applikationen förbinder sig att följa Dataskyddsförordningen
              (GDPR) vars syfte bl.a är att skydda den enskildes grundläggande
              rättigheter och friheter.
            </p>
            <br />
            <p>
              Vår integritetspolicy förklarar vilka personuppgifter vi
              behandlar, varför vi behandlar personuppgifterna och hur du kan
              utöva dina rättigheter för att göra invändningar mot
              integritetspolicyn.
            </p>
            <br />
            <p>
              Vi kan komma att uppdatera i denna Integritetspolicy med jämna
              mellanrum för att hålla den uppdaterad gentemot våra rutiner kring
              databehandling eller annat. Vid större eller väsentliga ändringar
              kommer vi att tillkännage våra Användare detta via lämpliga
              kommunikationskanaler eller på vår Webbsida.
            </p>
            <h3 class="pt-5">1. Personuppgiftsansvarig</h3>
            <p>
              NearbyStore Sverige AB ansvarar för all behandling av Användares
              personuppgifter.
            </p>

            <h3 class="pt-5">2. Nearbystores kontaktuppgifter</h3>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Bolaget</v-list-item-title>
                  <v-list-item-subtitle>NearbyStore</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Namn</v-list-item-title>
                  <v-list-item-subtitle>Nearbystore AB</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Org.nr</v-list-item-title>
                  <v-list-item-subtitle>559320-1477</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Adress</v-list-item-title>
                  <v-list-item-subtitle
                    >Norra Oskarsgatan 14, 582 73 Linköping,
                    Sverige</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>E-postadress</v-list-item-title>
                  <v-list-item-subtitle
                    >info@nearbystore.se</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Hemsida</v-list-item-title>
                  <v-list-item-subtitle>nearbystore.se</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <h3 class="pt-5">3. Behandlade personuppgifter</h3>
            <p>
              Personuppgifterna som vi samlar in och behandlar består av två
              allmänna kategorier: Användaruppgifter och analysdata.
            </p>
            <br />
            <h4>Användaruppgifter</h4>
            <p>
              Användaruppgifter är personuppgifter som du lämnat till oss när du
              registrerar ett konto i NearbyStores App, fyller i ett formulär
              eller prenumererar på nyhetsbrev. Vi samlar även in uppgifter om
              eventuella transaktioner och betalningar som du genomför via
              NearbyStores Tjänster eller genom Köp från oss.
            </p>
            <br />
            <p>
              En del av dessa användaruppgifter är nödvändiga för att kunna
              använda NearbyStores Tjänster och för att göra köp i Appen.
            </p>
            <p>Dessa uppgifter måste du ange oss:</p>
            <ul class="pl-10">
              <li>Fullständigt namn</li>
              <li>E-postadress</li>
              <li>Telefonnummer</li>
              <li>
                Betalningsuppgifter (hanteras av tredje part, ej NearbyStore)
              </li>
            </ul>
            <br />
            <p>
              Vi kan komma att behandla andra användaruppgifter såsom
              leveransadress, profilbild, information i samband med att du
              betygsätter våra tjänster, information vid kontakt med vår
              kundtjänst (via telefon, mail, chatt med flera), platsuppgifter
              eller annan information du lämnar till oss. Dessa
              användaruppgifter lämnar du frivilligt och är inte nödvändiga att
              lämna ut för att få använda våra Tjänster eller att få handla i
              vår App. Dock kan denna information hjälpa oss att höja din
              kundupplevelse samt vår service mot dig som Kund.
            </p>
            <br />
            <p>
              Utöver de användaruppgifter som inhämtats från dig via vår tjänst
              kan vi även komma att behandla personuppgifter som tillhandahålls
              av utomstående tjänsteleverantörer. Exempelvis om du loggar in med
              och ansluter ditt Google konto till oss eller dylikt.
            </p>
            <br />
            <h4>Analysdata</h4>
            <p>
              Analysdata syftar på den användningsinformation som vi samlar in
              när du använder dig av Nearbystores tjänster. Vi använder i regel
              inte denna data för att identifiera dig personligen, men ibland
              kan denna data gå att identifiera dig med. I dessa fall kommer vi
              att behandla den datan såsom vi behandlar personuppgifter.
            </p>
            <br />
            <p>
              Vi samlar in denna informationen om den tekniska enhet du använder
              vid nyttjande av vår App:
            </p>

            <ul class="pl-10">
              <li>Land</li>
              <li>IP-adress</li>
              <li>Webbläsarens typ och version</li>
              <li>Operativsystem</li>
              <li>Reklamidentifierare för din enhet</li>
              <li>Besöksidentifierare</li>
            </ul>
            <br />
            <p>
              Vi samlar in denna informationen kring ditt användande och
              beteende vid utnyttjande av vår App, Beställningsskärm och
              webbsida:
            </p>
            <ul class="pl-10">
              <li>
                Tid med tidpunkt och datum spenderad och vilken interaktion som
                gjorts i systemen
              </li>
              <li>Information om beställningar</li>
              <li>Fraser du sökt efter i systemen</li>
              <li>Vilka delar av systemen du besökt</li>
            </ul>
            <br />
            <p>Exempel på denna information:</p>
            <ul class="pl-10">
              <li>Interaktion med Nearbystores tjänster</li>
              <li>Information om genomförda beställningar</li>
              <li>Tid och datum som applikationen används</li>
            </ul>
            <br />
            <h4>Webbanalystjänster (Google Analytics)</h4>
            <p>
              NearbyStore använder sig av Google Analytics för att analysera
              data och skapa rapporter om hur användare nyttjar Nearbystores
              tjänster. Det används för att förbättra upplevelsen för användare.
            </p>
            <h3 class="pt-5">4. Ändamål för behandling av personuppgifter</h3>
            <p>
              NearbyStore behandlar personuppgifter för flera olika ändamål:
            </p>
            <br />
            <h4>Ändamål</h4>
            <p>
              Användaruppgifter sparas för att tillhandahålla NearbyStores
              tjänster. Samt för att kunna följa det avtal som upprättats mellan
              dig och NearbyStore.
            </p>
            <p>Exempel på användningsområden för användaruppgifter:</p>
            <br />
            <ul class="pl-10">
              <li>Vid köp av produkt som har krav på en viss ålder.</li>
              <li>Vid reklamation av produkt.</li>
              <li>Intyga på att det är din beställning.</li>
            </ul>
            <br />
            <p>
              Personuppgifter sparas för kommunikation och marknadsföring.
              NearbyStore behandlar dina personuppgifter i syfte för att
              informera om NearbyStores tjänster och för att marknadsföra
              NearbyStores tjänster.
            </p>
            <h3 class="pt-5">5. Mottagare</h3>
            <p>
              Dina uppgifter delas endast inom NearbyStores organisation och
              följer denna integritetspolicy. Användares personuppgifter kommer
              inte lämnas ut till tredje man, om det inte krävs p.g.a. tvingande
              regler eller order från domstol eller om vi får ditt godkännande
              att göra så. Oavsett om du har gett ditt godkännande till oss, kan
              du utan avgift återkalla ditt godkännande närsomhelst. Observera
              att ifall du lämnar personuppgifter direkt till tredjepart,
              exempelvis via en länk på vår hemsida så gäller deras villkor och
              riktlinjer för behandlingen av dina personuppgifter.
            </p>
            <br />
            <p>
              Vid händelse av sammanslagning, avyttrande av tillgångar eller
              ifall vi på andra sätt säljer delar eller hela verksamheten så kan
              vi komma att föra över personuppgifter till tredje part. Vi kommer
              dock fortsätta garantera dina uppgifters säkerhet samt meddela
              alla berörda Användare om detta skulle ske.
            </p>
            <h3 class="pt-5">6. Lagringsperiod</h3>
            <p>
              NearbyStore lagrar dina personuppgifter efter Sveriges lagar.
              Detta innefattar att din information kommer sparas så länge den är
              relevant. Detta gör att lagringsperioden kan variera.
            </p>
            <br />
            <p>
              Din användarinformation anses vara relevant till cirka 90 dagar
              efter att Användaren tagit bort sitt konto. Med undantag för data
              som är nödvändig i extraordinära situationer, till exempel vid
              rättsliga förfaranden.
            </p>
            <h3 class="pt-5">7. Dina rättigheter</h3>
            <p>
              Du som användare är skyddad och har även flertalet rättigheter
              enligt dataskyddsförordningen. Du kan läsa mer om dina rättigheter
              och vad som gäller på IMYs hemsida. All info i denna punkt (7.) är
              hämtad från IMYs hemsida
              <a
                href="https://www.imy.se/privatperson/dataskydd/"
                target="_blank"
                >IMY</a
              >
              2022-01-11
            </p>
            <br />
            <h4>Rätt till radering</h4>
            <p>
              Du har rätt att be oss radera dina personuppgifter som vi har
              sparade i vårt system. Vi kommer att fullfölja sådan begäran
              såvida vi inte har berättigade skäl att inte radera uppgifterna.
            </p>
            <br />
            <h4>Rätt till rättelse</h4>
            <p>
              Om du anser att vi lagrar felaktiga personuppgifter om dig eller
              att dessa är ej är kompletta kan du kontakta oss för att få dem
              rättade eller kompletterade. Du kan även göra detta själv genom
              att ändra användaruppgifterna i ditt konto hos oss.
            </p>
            <br />
            <h4>Rätt till tillgång</h4>
            <p>
              Du har även rätt att få tillgång, alltså ta del av dina
              personuppgifter som vi lagrar. Kontakta oss om du vill ha en kopia
              på de uppgifter vi lagrar om dig.
            </p>
            <br />
            <h4>Rätt till begränsning</h4>
            <p>
              I särskilda situationer har du rätt att kräva att behandlingen av
              dina personuppgifter begränsas. Detta kan exempelvis vara under
              tiden vi behandlar din begäran om att radera dina uppgifter. Du
              kan läsa mer om detta på
              <a href="https://www.imy.se/" target="_blank">imy.se</a> eller
              kontakta oss.
            </p>
            <br />
            <h4>Rätt att göra invändningar</h4>
            <p>
              Du kan i vissa fall invända mot personuppgiftsansvariges
              (NearbyStore) behandling av dina personuppgifter. Exempelvis om du
              anser att dessa behandlas felaktigt eller för andra ändamål än vad
              som är nödvändigt. Sådana invändningar kan emellertid påverka din
              användarupplevelse.
            </p>
            <br />
            <h4>Rätt till dataportabilitet</h4>
            <p>
              Du kan av oss få ut de personuppgifter du själv lämnat till oss i
              ett vanligt förekommande filformat för att självständigt skicka
              eller överföra dessa till tredje part.
            </p>
            <br />
            <p>
              Om du vill utöva dina rättigheter, vill du utöva dina rättigheter
              är du välkommen att kontakta oss i första hand via
              info@nearbystore.se eller i andra hand med ovan nämnda
              kontaktmetoder. När du gör sådan begäran kan vi komma att kräva
              att du lämnar ut information som påvisar att du är den Användaren
              du påstår dig vara. Vi behöver även ditt fullständiga namn och
              kontaktuppgifter (e-post och telefonnummer). Ifall vi mottar
              begäranden som är orimligt repetitiva eller tydligt ogrundade kan
              komma att avvisa eller ta ut en avgift för ärendet.
            </p>
            <h3 class="pt-5">8. Klagomål</h3>
            <p>
              Om Användaren anser att vår behandling av personuppgifterna inte
              överensstämmer med gällande dataskyddslagar kan klagomål lämnas
              till IMY som är Sveriges tillsynsmyndighet för dataskydd eller
              till den lokala tillsynsmyndigheten för dataskydd i sin egen
              hemvist om NearbyStore erbjuder sina Tjänster i det land som
              Användaren har som sin hemvist.
            </p>
            <h3 class="pt-5">9. Informationssäkerhet</h3>
            <p>
              NearbyStore använder sig av flera åtgärder för att skydda din
              data, exempelvis genom kryptering och brandväggar. Åtgärderna
              innefattar bland annat rättighetssystem som ser till att en annan
              Användare inte kan komma åt din data; servercertifikat som behöver
              förnyas flera gånger per år för att minska påverkan av intrång,
              även om intrång är osannolikt; säkra system som behandlar din
              identitet, dina känsliga uppgifter som kort och
              inloggningsinformation vilket innebär att det krävs flera nivåer
              av intrång: inte bara i NearbyStores egna system för att få ut din
              information. Tillsammans verkar åtgärderna för att göra det
              jobbigt, svårt, och i vissa fall omöjligt för attackerare att
              komma över din data eller delar av din data.
            </p>
            <br />
            <p>
              Om åtgärderna mot förmodan inte är tillräckliga och en incident
              skulle inträffa tar NearbyStore ansvar och informerar de berörda
              Användare och andra drabbade parter som kan underlätta för att
              minska incidentens påverkan. Allt i enlighet med gällande
              dataskyddslagar.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts"></script>
<style></style>
