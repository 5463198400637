<template>
  <div class="search-page">
    <v-container class="search-container" @click.stop>
      <div class="search-label">
        <span>Sök</span>
        <v-icon class="icon">mdi-magnify</v-icon>
      </div>
      <v-text-field
        v-model="searchTerm"
        @keyup.enter="handleSearch"
        solo
        flat
        hide-details
        placeholder="Sök vara..."
        class="search-field"
      ></v-text-field>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SearchPage",
  data() {
    return {
      searchTerm: "",
    };
  },

  mounted() {
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    handleSearch() {
      if (this.searchTerm.trim()) {
        this.$router.push({
          name: "search",
          params: {
            searchTerm: encodeURIComponent(this.searchTerm.trim()),
          },
        });
      }
    },

    toggleBodyScroll() {
      document.documentElement.style.overflow = "hidden";
    },
  },
};
</script>

<style scoped>
.icon {
  margin-left: 10px;
}

.search-page {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: rgba(0, 58, 112, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: white;
  font-size: 24px;
  width: 95%;
  max-width: 400px;
}

.search-label v-icon {
  margin-right: 8px;
}

.search-field {
  width: 95%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
}
</style>
