<template>
  <v-breadcrumbs class="bold-h4 pa-0" :items="breadcrumbItems">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item class="mb-2" :to="item.to" :disabled="item.disabled">
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon icon="mdi-chevron-right" class="pa-0" size="30"></v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: "breadcrumbs",
  props: {
    breadcrumbItems: {
      type: Array,
      required: true,
      default: [],
    },
  },
};
</script>
<style scoped>
:deep(.v-breadcrumbs-divider) {
  padding: 0;
}
</style>
