<template>
  <div>
    <LoginModal :visible="loginModal" @close="loginModal = false"></LoginModal>
    <!-- Heart Icon -->
    <v-icon
      @click="handleHeartClick"
      :class="{ filled: isSaved }"
      color="error"
    >
      {{ isSaved ? "mdi-heart" : "mdi-heart-outline" }}
    </v-icon>
    <v-dialog v-model="addToListDialog" width="650px" height="80vh">
      <v-card>
        <v-card-text class="scrollable-content">
          <v-col align="center">
            <p class="bold-h1 mb-4">{{ $t("add_to_list") }}</p>
            <div
              class="mt-4"
              v-for="list in shoppingLists"
              :key="list._id"
              :style="
                isProductInList(list._id)
                  ? 'border: 2px solid #003A70; border-radius: 6px;'
                  : 'border: 2px solid grey; border-radius: 6px;'
              "
            >
              <v-card
                class="pa-4"
                elevation="0"
                @click="toggleProductInList(list)"
              >
                <v-col align="start">
                  <v-row align="center">
                    <v-icon color="primary">{{
                      isProductInList(list._id)
                        ? "mdi-check-circle"
                        : "mdi-checkbox-blank-circle-outline"
                    }}</v-icon>
                    <v-col class="pa-0 ml-4">
                      <p class="bold-h4">
                        {{ list.name }}
                      </p>
                      <p>
                        {{
                          $t("items_count", {
                            count: list.products.length,
                          })
                        }}
                      </p></v-col
                    >
                  </v-row>
                </v-col>
              </v-card>
              <v-expansion-panels class="pt-0">
                <v-expansion-panel elevation="0" class="pa-0">
                  <v-expansion-panel-title class="body-2">{{
                    $t("view_items")
                  }}</v-expansion-panel-title>
                  <v-expansion-panel-text class="pa-0">
                    <v-card
                      v-for="(p, index) in list.products"
                      :key="p.product._id"
                      class="pa-2 ma-2 mt-4"
                      elevation="0"
                    >
                      <v-row>
                        <v-col
                          align="center"
                          justify="center"
                          cols="2"
                          sm="2"
                          class="pa-1"
                        >
                          <v-img
                            :src="p.product.thumbnail.url"
                            :alt="p.product.thumbnail.alt"
                            alt="Product Image"
                            height="100"
                          />
                        </v-col>
                        <v-col align="start">
                          <v-col sm="12" class="pa-0">
                            <p class="bold-h4 pt-0">
                              {{ p.product.name }}
                            </p></v-col
                          >
                          <v-col sm="12" class="pa-0">
                            <p class="body-2">
                              {{
                                p.product.manufacturer +
                                " " +
                                p.product.displayWeight
                              }}
                            </p></v-col
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-expansion-panel-text></v-expansion-panel
                ></v-expansion-panels
              >
            </div>

            <v-btn
              @click="showNewList = true"
              class="mt-4"
              color="primary"
              v-if="!showNewList"
            >
              <p>{{ $t("create_new_list") }}</p>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-col v-if="showNewList" class="mt-4 pa-4" align="start">
              <p class="bold-h3">{{ $t("create_new_list") }}</p>
              <v-row align="center" class="mt-4">
                <v-text-field
                  v-model="newListName"
                  clearable
                  validate-on="submit"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  single-line
                  hide-details
                  placeholder="Namn"
                  persistent-hint
                />
                <v-col cols="2" align="center">
                  <v-btn
                    color="primary"
                    flat
                    @click="createNewList"
                    :loading="newListLoading"
                    >{{ $t("save") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="addToListDialog = false"
            color="primary"
            variant="flat"
          >
            {{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShoppingListAPI from "@/api/SaveAPI.js"; // Import the ShoppingListAPI

export default {
  name: "SaveProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownVisible: false,
      loginModal: false,
      addToListDialog: false,
      loginPromptVisible: false,
      createListModalVisible: false,
      showNewList: false,
      newListLoading: false,
      newListName: "",
      savedLists: [], // Array to store the list IDs where the product is saved
      shoppingLists: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    /**
     * Checks if the product is saved in any list.
     * @return {boolean} True if the product is saved in any list.
     */
    isSaved() {
      return this.savedLists.length > 0;
    },
  },
  methods: {
    /**
     * Handles the click on the heart icon. If the user is not logged in,
     * shows the login prompt. If there are no lists, shows the create list modal.
     * Otherwise, toggles the dropdown visibility.
     */
    handleHeartClick() {
      if (!this.user) {
        this.loginModal = true;
      } else {
        this.addToListDialog = true;
      }
    },
    /**
     * Toggles the visibility of the dropdown menu.
     */
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    /**
     * Checks if the product is in the specified list.
     * @param {string} listId - The ID of the list.
     * @return {boolean} True if the product is in the list.
     */
    isProductInList(listId) {
      return this.savedLists.includes(listId);
    },
    /**
     * Toggles the product in the specified list. If the product is in the list,
     * removes it. Otherwise, adds it.
     * @param {Object} list - The list.
     */
    async toggleProductInList(list) {
      if (this.isProductInList(list._id)) {
        await this.removeFromList(list);
      } else {
        await this.addToList(list);
      }
    },
    /**
     * Adds the product to the specified list.
     * @param {Object} list - The list.
     */
    async addToList(list) {
      list.products.push({ product: this.product, quantity: 1 });
      this.savedLists.push(list._id);
      try {
        await ShoppingListAPI.updateShoppingList(list._id, {
          name: list.name,
          products: list.products.map((p) => ({
            product: p.product._id,
            quantity: p.quantity,
          })), // Ensure only product IDs are sent
          onlineAvailability: list.onlineAvailability,
        });
      } catch (error) {
        console.error("Error adding product to list:", error);
      }
    },
    /**
     * Removes the product from the specified list.
     * @param {Object} list - The list.
     */
    async removeFromList(list) {
      list.products = list.products.filter(
        (p) => p.product._id !== this.product._id
      );
      this.savedLists = this.savedLists.filter((id) => id !== list._id);
      try {
        await ShoppingListAPI.updateShoppingList(list._id, {
          name: list.name,
          products: list.products.map((p) => ({
            product: p.product._id,
            quantity: p.quantity,
          })),
          onlineAvailability: list.onlineAvailability,
        });
      } catch (error) {
        console.error("Error removing product from list:", error);
      }
    },

    /**
     * Creates a new list with the specified name and adds the product to the new list.
     */
    async createNewList() {
      this.newListLoading = true;
      if (this.newListName.trim() !== "") {
        const newList = {
          name: this.newListName,
          products: [{ product: this.product._id, quantity: 1 }],
          onlineAvailability: true,
        };
        try {
          const response = await ShoppingListAPI.createShoppingList(newList);
          this.shoppingLists.push({
            ...response.data,
            products: [{ product: this.product, quantity: 1 }],
          });
          this.newListName = "";
          this.createListModalVisible = false;
          this.savedLists.push(response.data._id);
          this.newListLoading = false;
          this.showNewList = false;
        } catch (error) {
          this.newListLoading = false;
          console.error("Error creating new list:", error);
        }
      }
    },
    /**
     * Loads the user's shopping lists from the API.
     */
    async loadUserShoppingLists() {
      try {
        const response = await ShoppingListAPI.getUserOwnShoppingLists();
        this.shoppingLists = response.data.map((list) => ({
          ...list,
          products: list.activeProducts || [], // Ensure products is an array
        }));
        this.savedLists = this.shoppingLists
          .filter((list) =>
            list.products.some((p) => p.product._id === this.product._id)
          )
          .map((list) => list._id);
      } catch (error) {
        console.error("Error loading shopping lists:", error);
      }
    },
  },
  async mounted() {
    if (this.user) {
      await this.loadUserShoppingLists();
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
.scrollable-content {
  height: 74vh; /* Set this to the desired height */
  overflow-y: auto;
}
.save-product-container {
  position: relative;
  display: flex;
  height: fit-content;
}

.heart-icon {
  cursor: pointer;
  font-size: 24px;
  color: red;
}

.filled {
  color: red;
}

.dropdown-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 180px;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.blue-check {
  color: #003a70;
  margin-left: 20px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.create-new-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f8f8f8;
}

.plus-icon {
  color: #003a70;
  margin-left: 20px;
  font-weight: bold;
}

/* Custom Login Prompt Modal Styles */
.login-prompt-overlay,
.create-list-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-prompt-modal,
.create-list-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.login-prompt-title,
.create-list-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.login-prompt-actions,
.create-list-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.login-prompt-btn,
.create-list-btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-prompt-btn.yes,
.create-list-btn.create {
  background-color: #003a70;
  color: white;
}

.login-prompt-btn.yes:hover,
.create-list-btn.create:hover {
  background-color: #005092;
}

.login-prompt-btn.no,
.create-list-btn.cancel {
  background-color: #c94631;
  color: white;
}

.login-prompt-btn.no:hover,
.create-list-btn.cancel:hover {
  background-color: darkred;
}

.create-list-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>
