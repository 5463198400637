<template>
  <!-- Main container that includes the banner, breadcrumbs, and products list -->
  <v-col>
    <!-- Vuetify Loading Component -->

    <!-- Content to display when not loading -->
    <div>
      <!-- BannerContainer displays the banner image if available -->
      <BannerContainer v-if="bannerImage" :imageUrl="bannerImage" />

      <Breadcrumbs :items="breadcrumbItems" />

      <!-- AllProducts component displays the list of products related to the banner -->
      <v-col class="pa-0">
        <p class="bold-h1">{{ bannerTitle }}</p>
        <p class="body-2 mb-10">{{ bannerDescription }}</p>
        <v-row v-if="loading" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <AllProducts v-else :products="products" :hideLoadMore="true" />
      </v-col>
    </div>
  </v-col>
</template>

<script>
import { ref, computed, watch } from "vue";
import AllProducts from "../AllProducts.vue";
import BannerAPI from "@/api/BannerAPI";
import BannerContainer from "../ProductBanner.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Breadcrumbs from "../Breadcrumbs.vue";
/**
 * FeaturedProductsProducts Component.
 * Displays a page with a banner and associated products, based on a specific banner ID.
 * Redirects to the homepage if the storeId is not defined.
 */
export default {
  name: "FeaturedProductsProducts",
  components: {
    AllProducts,
    BannerContainer,
    Breadcrumbs,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const products = ref([]);
    const bannerTitle = ref("");
    const bannerDescription = ref("");
    const bannerImage = ref("");
    const loading = ref(true); // Tracks if the data is still loading

    const { t, locale } = useI18n();

    /**
     * Fetches banner information and associated products by banner ID.
     * Redirects to the homepage if storeId is undefined.
     * Sets banner title, description, and image URL.
     */
    const fetchBannerAndProducts = async () => {
      if (!store.state.location.storeId) {
        router.push("/"); // Redirect to homepage if storeId is undefined
        return;
      }
      const bannerId = route.params.id;
      try {
        const response = await BannerAPI.getBannerById(bannerId);
        if (response.data && response.data.products) {
          products.value = response.data.products;
          bannerTitle.value = response.data.title || "";
          bannerDescription.value = response.data.description || "";
          bannerImage.value =
            response.data.mainImage?.url || response.data.image?.url || "";
        }
        loading.value = false; // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching banner data:", error);
        loading.value = false; // Set loading to false even if there is an error
      }
    };

    /**
     * Watches for changes in storeId and updates the banner data accordingly.
     */
    watch(
      () => store.state.location.storeId,
      (newStoreId) => {
        if (newStoreId) {
          fetchBannerAndProducts();
        }
      },
      { immediate: true }
    );

    watch(
      () => store.state.currentLang,
      async () => {
        try {
          fetchBannerAndProducts();
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    );

    /**
     * Watches for changes in route parameters to re-fetch banner data.
     */
    watch(() => route.params, fetchBannerAndProducts, { immediate: true });

    /**
     * Computes breadcrumb items for the component.
     * Includes a link to the home page and the banner title.
     */
    const breadcrumbItems = computed(() => [
      {
        text: t("home"),
        to: {
          name: "handla",
        },
      },
      { text: bannerTitle.value, disabled: true },
    ]);

    return {
      products,
      bannerTitle,
      bannerDescription,
      bannerImage,
      breadcrumbItems,
      loading,
      t,
      locale,
    };
  },
};
</script>

<style scoped>
/* Style for the breadcrumb navigation */
.breadcrumbs {
  max-width: 1300px;
  margin: auto;
  padding-left: 20px;
}

/* Style for the overall layout container */
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}
</style>
