<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Användarvillkor</v-card-title>
          <v-card-text>
            <p>
              Vid köp från eller användande av NearbyStores tjänster (“Tjänster,
              Handla”) så godkänner du våra användarvillkor (“Användarvillkor”).
            </p>
            <p>
              Var god läs igenom våra Användarvillkor innan du använder våra
              tjänster eller gör ett köp hos oss och ifall du inte godkänner
              våra Användarvillkor ber vi dig avstå från köp eller utnyttjande
              av våra tjänster. Våra Användarvillkor tillämpas på all användning
              av tjänster och försäljning utfärdad av NearbyStore Sverige AB.
            </p>
            <p>
              Uppdatering av Användarvillkoren skedde senast 8 januari 2021.
            </p>
            <h3 class="pt-5">1. Definitioner</h3>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong
                      >“Användare”, “du”, “dig” “Kund”</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Avser den person som använder våra tjänster eller köper
                    produkter, livsmedel eller andra varor av
                    NearbyStore</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong
                      >“Försäljning”, “Köp” “order”</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Alla köp eller transaktioner i utbyte mot varor eller
                    tjänster som erbjuds av NearbyStore eller köp av
                    varor/tjänster från NearbyStore genom tredje
                    part.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Varor”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >De dagligvaruprodukter, livsmedel eller andra varor som
                    erbjuds till försäljning genom NearbyStores tjänster och
                    plattformar.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Avtalet”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Avser Användarvillkoren.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Tjänster”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Avser både kostnadsfria och betaltjänster som
                    tillhandahålls av NearbyStore .</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Butiken”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Den fysiska platsen för köp eller utlämning av dina varor
                    eller orders.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Plocktider”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Avser den tid det tar för oss att plocka en order. Denna tid
                    kan variera beroende av flera faktorer, bland annat hur
                    många kunder som lagt order eller ordnarnas
                    storlek/komplexitet.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Appen”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Avser mobilapplikationen vi tillhandahåller Android, iOS med
                    flera mobila enheter.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Beställningsskärm”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Avser de obemannade beställningsskärmar som finns i våra
                    fysiska butiker.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong>“Beställningsskärm”</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Avser de obemannade beställningsskärmar som finns i våra
                    fysiska butiker.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><strong
                      >“Bolaget”, “NearbyStore”, “vi”, “oss”</strong
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Bolaget</v-list-item-title>
                  <v-list-item-subtitle>NearbyStore</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Namn</v-list-item-title>
                  <v-list-item-subtitle>Nearbystore AB</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Org.nr</v-list-item-title>
                  <v-list-item-subtitle>559320-1477</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Adress</v-list-item-title>
                  <v-list-item-subtitle
                    >Norra Oskarsgatan 14, 582 73 Linköping,
                    Sverige</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>E-postadress</v-list-item-title>
                  <v-list-item-subtitle
                    >info@nearbystore.se</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Hemsida</v-list-item-title>
                  <v-list-item-subtitle>nearbystore.se</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <h3 class="pt-5">2. Kontakt</h3>
            <p>
              Du hittar kontaktuppgifter till oss på vår hemsida eller angivet
              nedan. Du kan också skicka ett e-postmeddelande till
              <a href="mailto:info@nearbystore.se">info@nearbystore.se</a>.
            </p>
            <h3 class="pt-5">3. Kontakt</h3>
            <p>
              Då vi kommunicerar Plocktider vid beställningar hos oss eller gör
              andra Tidsuppskattningar i vår kommunikation med dig som Kund,
              direkt eller indirekt via våra kommunikationskanaler, sociala
              medier, hemsida eller App (“Tidsuppskattningar”); Så gäller dessa
              inte som exakta tidsangivelser utan skall ses som gissningar.
              NearbyStore tar ej ansvar för skador, kostnader eller andra
              förluster orsakade av dessa Tidsuppskattningar. Om NearbyStore
              kommunicerar en Tidsuppskattning skall den dock ses som aktuell
              och du skall hämta en Order eller Varor hos NearbyStore inom
              uppskattad tidsangivelse.<br /><br />
              Notera att vissa Tidsuppskattningar som till exempel Sista
              upphämtningstid, sista tidpunkt för när en Order får hämtas
              (”Sista upphämtningstid”), är bindande för dig som Kund hos
              NearbyStore att följa. Annars kan du förlora rätten att hämta upp
              den Order du beställt. Detta kan medföra att du debiteras fullt
              pris för de Varor som inte kan säljas på nytt och/eller andra
              kostnader för NearbyStore i samband med beställningen.
            </p>
            <h3 class="pt-5">
              4. Beskrivning av NearbyStores Tjänster, Försäljning och Varor
            </h3>
            <p>
              NearbyStore bedriver dagligvaruhandel med fokus på
              livsmedelsförsäljning. Försäljningen sker via en egenutvecklad
              digital plattform. Den går att nå genom vår App och våra
              Beställningsskärmar.<br /><br />
              Genom den digitala plattformen kan Användare köpa livsmedel och
              dagligvaruprodukter samt ta del av flera smarta funktioner och
              tjänster såsom exempelvis “sparade varukorgar”.<br /><br />
              Genom tredje part kan hemkörning eller företagsleverans komma att
              erbjudas. NearbyStore tar ej ansvar för några Varor efter att de
              har lämnats ut av NearbyStore till tredje part, då är det tredje
              parts ansvar att varorna hanteras och levereras på korrekt vis.
            </p>
            <h3 class="pt-5">5. Utbud, Öppettider</h3>
            <p>
              Vi strävar alltid för att kunna erbjuda ett så brett och aktuellt
              produktutbud som det går. Problem med leverantörer, distribution
              eller minskat intresse för produkter samt andra anledningar kan
              komma leda till att produkter tas bort från sortimentet.<br /><br />
              Utbudet kan även komma att variera kraftigt mellan olika butiker
              och försäljningsställen beroende på var butiken befinner sig,
              förvaringsmöjligheter/kapacitet och andra förutsättningar på varje
              enskild butik/utlämningsställe.<br /><br />
              NearbyStore förbehåller sig rätten till att ändra produktutbudet
              vid varje given tidpunkt och kan inte heller garantera att det
              sortiment som är tillgängligt på vår plattform alltid kommer vara
              det. Vi vill även vara tydliga med och friskskriva oss ifrån
              felaktigt uppladdade produkter eller missar att ta bort produkter
              som inte längre är tillgängliga eller till salu.<br /><br />
              NearbyStore förbehåller sig rätten till att med kort förvarning
              stänga en butik eller möjlighet att handla vid
              <strong>a)</strong> Driftstörning/tekniskt problem
              <strong>b)</strong> personalbrist
              <strong>c)</strong> Myndighetsbeslut eller Force Majeure
              <strong>d)</strong> Andra händelser som hindrar optimal funktion
              för anställda och dig som Kund.<br /><br />
              Därmed kan Kunder bli nekade att köpa eller få sin Order avbruten
              av ovan angivna anledningar. Vid sådan händelse kommer Kund få
              möjlighet till full ersättning av ordervärdet/Köpet på den/de
              produkter som ej kan levereras till Kund.
            </p>
            <h3 class="pt-5">6. Köp av Varor</h3>
            <h4>6.1. Villkor</h4>
            <p>
              De användaruppgifter som Användaren har uppgett vid registreringen
              av ett nytt konto hos NearbyStore är och ska behandlas
              konfidentiellt och användaruppgifterna får inte spridas eller
              förvaras åtkomligt för annan än Användaren själv. Det är endast
              tillåtet för en Användare att ha ett (1) aktivt användarkonto.
            </p>
            <br />
            <p>
              Om Användaren misstänker att dennes konto har hackats, obehörig
              person fått kännedom om Användarens användaruppgifter eller att
              obehörig person på annat sätt har tillgång till Användarens konto
              ska Användaren omedelbart kontakta NearbyStore och tillhandahålla
              denna information. Användaren ansvarar för att ingen annan har
              tillgång till dennes användaruppgifter och är införstådd med att
              all aktivitet och användning samt Köp som gjorts via Användarens
              användarkonto är dennes ansvar.
            </p>
            <br />
            <p>
              All Försäljning av produkter med åldersbegränsning sker endast
              till kund som kan verifiera sig genom BankID. Du som köper en
              åldersbegränsad produkt behöver vid varje köptillfälle av dessa
              varor legitimera att du uppfyller åldersgränsen och bekräftar att
              du är införstådd att du inte får ge, bjuda eller sälja vidare
              åldersbegränsade produkter till minderårig. Det är 18 årsgräns för
              att få köpa alkohol, snus och nikotinprodukter. Vi har en
              åldersgräns på 15 år för att få handla energidryck.
            </p>
            <br />
            <p>
              När du lägger en beställning via vår App, Beställningsskärmar
              eller på annat sätt via vår plattform så behöver du ange en
              Upphämtningstid för när du tänkt hämta din Order. Denna går att
              ändra fram tills att vi börjar plocka din Order. När vi börjar
              plocka din Order kommer du få en Sista upphämtningstid, sista
              tidpunkt för när en Order får hämtas. Om du missar att hämta ut
              din Order innan denna tidsangivelse kan du förlora rätten att
              hämta upp den Order du beställt. Detta kan medföra att du
              debiteras fullt pris för de Varor som inte kan säljas på nytt
              och/eller andra kostnader för NearbyStore i samband med
              beställningen.
            </p>
            <br />
            <p>
              När du som Kund ska hämta ut din beställning och trycker på
              knappen “hämta beställning” bekräftar du att du är på plats i den
              butik du angivet som upphämtningsplats samt att du bevakar
              varubandet så att ingen annan tar dina varor. NearbyStore ansvarar
              inte för varor som stjäls efter att du tryckt på knappen “Hämta
              beställning”.
            </p>
            <br />
            <p>
              Vi kommer inte att börja plocka en Order om du inte angivit en
              Upphämtningstid. En Order börjar plockas när den vid tidpunkten
              aktuella Tidsuppskattade plocktiden = tiden som är kvar tills
              angiven Upphämtningstid. En lagd order måste hämtas ut senast en
              (1) månad efter att den lagts om ingen annan överenskommelse sker.
            </p>
            <br />
            <h4>6.2. Betalning</h4>

            <p>
              Vi tillhandahåller flera olika betalningslösningar på vår
              plattform. De betalningssätt som är tillgängliga för dig kan komma
              att variera beroende på var du befinner dig, hur gammal du är, om
              du är medlem eller ej samt ifall du betalar via Appen eller en
              Beställningsskärm.
            </p>
            <br />
            <p>
              Betalning sker i samband med beställningen via vår plattform om
              inget annat överenskommits.
            </p>
            <br />
            <h4>6.3. Innehåll</h4>

            <p>
              I de fall din Order innehåller färdigförpackade livsmedel så är
              det upp till dig som Kund att läsa innehållsförteckningen på
              förpackningen innan förtäring. Särskilt viktigt är det att läsa
              Varans innehållsförteckning om du är allergisk mot ett visst
              livsmedel eller ämne. NearbyStore reserverar sig för att
              innehållsförteckningen som presenteras på vår plattform eller vid
              Orderläggning kan vara ofullständig eller felaktig.
            </p>
            <br />
            <p>
              Vid varje givet tillfälle som informationen vi angett inte
              överensstämmer med Varans egna innehållsförteckning, eller annan
              information angiven på Varans förpackning, så är det Varans egen
              innehållsförteckning och information som är korrekt. Har du frågor
              kring innehåll i de produkter du köpt ska du kontakta tillverkaren
              av produkten i första hand.
            </p>
            <br />
            <h4>6.4. Rabatter och priser</h4>

            <p>
              Vi prissätter våra varor för att kunna erbjuda konkurrenskraftiga
              varor till våra kunder. Vår prissättning kommer kunna variera
              mellan Användare beroende på personliga rabatter, förmåner, plats
              med flera anledningar. Priserna kan även komma att variera
              beroende på vilken tid det är på dygnet. Vi förbehåller oss rätten
              att ändra priser även då varor lagts till i kundkorgen, men inte
              efter att en beställning lagts.
            </p>
            <br />
            <p>
              Rabatter som ges för nya kunder eller som introduktionsrabatter
              gäller endast en (1) gång per registrerad Användare. Detsamma
              gäller andra rabatter på vår plattform om inte andra
              villkor/regler specifikt anges i samband med rabattkoden. Du kan
              endast använda en (1) rabattkod eller erbjudande per varje enskild
              Order om inget annat anges i samband rabattkoden eller
              erbjudandet.
            </p>
            <br />
            <p>
              Tillgodokvitton, kuponger, värdecheckar med ett bestämt nominellt
              värde (“Kuponger”) utfärdade/skapade av NearbyStore kan användas
              för att betala för delar av eller hela Ordervärdet vid Köp hos oss
              såvida inte andra begränsningar uttrycks specifikt på Kupongen i
              fråga. Kupongen kan endast användas en (1) gång och förbrukas då
              den används, oavsett ifall hela Kupongens värde använts i Ordern
              eller ej. Då Kupongens utgångsdatum passerat blir den värdelös och
              kan inte återaktiveras, användas för Köp eller på något sätt
              inneha kvarvarande värde. Ingen av våra Kuponger går att byta in
              mot kontanter.
            </p>
            <br />
            <p>
              Vid felaktigt användande eller missbruk av erbjudanden, kampanjer
              eller Kuponger kan NearbyStore komma att återkalla, avsluta eller
              ogiltigförklara dessa. Kunder som missbrukar eller på annat sätt
              överutnyttjar Kuponger, erbjudanden eller kampanjer kan komma att
              begränsas tillgång till dessa eller vid allvarliga fall även alla
              våra Tjänster. Vi förbehåller oss rätten att debitera personer som
              grovt missbrukat dessa eller åstadkommit NearbyStore skada i
              samband med dess handlande.
            </p>
            <h3 class="pt-5">7. Reklamation och klagomäl</h3>
            <p>
              Du som kund har enligt distansavtalslagen rätt att utnyttja din
              ångerrätt på 14 dagar från det att Varan, eller väsentlig del av
              Varan, lämnats ut gentemot oss (“Tidsfristen”). Du måste meddela
              oss skriftligen att du avser använda din ångerrätt innan
              Tidsfristen löpt ut.
            </p>
            <br />
            <p>
              Känsliga livsmedel eller Varor med kort hållbarhet, kylda och
              frysta Varor, oförpackade, lösviktsvaror samt varor vars
              försegling brutits kan ej returneras av hygien och hälsoskäl samt
              på grund av risker med hantering (exempelvis bruten kylkedja).
              Varor där det inte går att garantera livsmedelssäkerheten eller
              som uttryckligen undantas i konsumentskyddslagstiftningen är
              heller inte möjliga att returnera. För mer information om varor
              och tjänster undantagna från ångerrätt kan du besöka
              Konsumentverkets webbplats.
            </p>
            <br />
            <p>
              Efter att Kund tagit emot sina Varor är det Kunden som är ansvarig
              för dem. Det betyder att Varor som hanteras illa eller som
              returneras i sämre skick, avsevärt förändrats eller inte är enligt
              varans ursprungliga skicka, kommer den återbetalda summan
              reduceras delvis eller med varans fulla värde.
            </p>
            <br />
            <p>
              I de fall du fått en felaktig/skadad Vara eller saknar en Vara,
              som du med kvitto kan visa att du beställt, kan du i samråd med en
              medarbetare på NearbyStore få en ersättningsprodukt eller full
              återbetalning för de produkter som du ej mottagit. Notera att
              saknad produkt måste anmälas innan varorna tas ur
              butiken/uthämtningsstället.
            </p>
            <br />
            <h4>
              För att returnera en skadad/felaktig vara gör man enligt följande:
            </h4>

            <ol class="pl-10">
              <li>
                Ta bild på problemet (Om du fått fel vara eller skadad produkt
                exempelvis) och skicka med kvittot på köpet.
              </li>
              <li>
                Skicka ett e-postmeddelande till
                <a href="mailto:info@nearbystore.se">info@nearbystore.se</a> med
                ämnet ”Retur ordernummer” och beskriv problemet så svarar vi så
                fort vi kan. Glöm inte att nämna kontaktuppgifter i mailet.
              </li>
            </ol>
            <br />
            <p>
              Ifall du har andra klagomål eller förslag till förbättring på de
              Varor eller tjänster vi levererat är du välkommen att kontakta oss
              på <a href="mailto:info@nearbystore.se">info@nearbystore.se</a>.
            </p>
            <h3 class="pt-5">8. Kundåtaganden</h3>
            <p>
              Du som Handlar eller använder dig av NearbyStores Tjänster
              godkänner därmed detta Avtal och garanterar att du är minst 18 år
              samt att inga andra hinder föreligger för att du skall kunna
              godkänna dessa Användarvillkor. Du har även ansvar för att de
              uppgifter du anger vid registrering av konto hos oss är korrekta
              samt att du äger (eller har godkännande från ägaren att använda)
              de bank/kreditkort som du använder för Köp hos oss.
            </p>
            <br />
            <h3 class="pt-5">9. Immateriella rättigheter</h3>

            <p>
              Allt upphovsrättsligt skyddat material på NearbyStores Webbsida,
              App, Beställningsskärmar eller andra immateriella rättigheter
              kopplade till våra Tjänster eller Försäljning får inte användas
              utan NearbyStores, eller den verkliga upphovsmannens, tillstånd.
              Alla dessa immateriella rättigheter är och ska förbli
              upphovsmännens egendom. Detta innefattar alltså bland annat
              databas, källkod, fotografier, grafiskt material, patent,
              varumärken, designrättigheter, know-how, och alla andra typer av
              upphovsrättsskyddat material samt immateriella rättigheter
              (“Immateriella Rättigheter”).
            </p>
            <br />
            <p>
              Du som Användare har inte rätt att utan NearbyStores skriftliga
              godkännande kopiera, distribuera, på nytt skapa, kommersiellt
              exploatera, hävda eller på något annat sätt dra nytta av några av
              dessa Immateriella Rättigheter eller upphovsrättsskyddat material.
            </p>
            <h3 class="pt-5">10. Tillämplig lag och tvist</h3>
            <p>
              Användarvillkoren regleras av svensk lag och de lagstadgade
              konsumenträttigheter som gäller i det land du har som hemvist.
            </p>
            <br />
            <p>
              Tvister som uppstår i detta Avtal ska i första hand försöka lösas
              mellan Kunden och NearbyStore genom överenskommelse. I de fall
              parterna inte kan enas eller inte kommer fram till en
              överenskommelse ska tvist i andra hand avgöras av den myndighet
              som hanterar konsumenträttsfrågor. Som sista utväg ska tvist
              avgöras i allmän domstol.
            </p>
            <h3 class="pt-5">11. Avtalstid och uppsägning</h3>
            <p>
              Observera att NearbyStore när som helst kan komma att upphöra
              eller tas bort, tillfälligt eller permanent, Därför ber vi dig att
              inte spara viktig information eller på något sätt använda våra
              tjänster som backup av data. Vi gör vad vad vi kan för att våra
              tjänster skall fungera smärtfritt och ge dig som Användare en bra
              upplevelse. Med det sagt kan vi varken garantera eller lova att
              våra Tjänster eller Varor fungerar felfritt. Vi kommer dessutom
              att förneka allt ansvar som vi inte uttryckligen åtagit oss i
              detta Användaravtal eller i enlighet med gällande lagar och
              regler.
            </p>
            <br />
            <p>
              NearbyStore förbehåller sig rätten att med omedelbar verkan sluta
              erbjuda dess Tjänster och Försäljning tillfälligt eller permanent.
              Även att begränsa eller helt hindra enskilda kunder som missbrukar
              våra Tjänster eller bryter mot detta Användaravtal. Därigenom kan
              dessa kunders lagda, förbeställda och pågående ordrar komma att
              avbrytas. Detta gäller även ifall individ åsamkat annan skada
              eller på något sätt medfört olägenheter för NearbyStore.
            </p>
            <br />
            <p>
              Personer som använder våra tjänster för att göra brott, skada mot
              medmänniska, egendom, langar vidare åldersbegränsade produkter
              till minderåriga kommer även dessa att kunna stängas av eller helt
              förlora rätten till att få handla på NearbyStores plattform eller
              via våra Tjänster.
            </p>
            <br />
            <p>
              NearbyStore förbehåller även sig rätten till att annullera ordrar,
              stänga av Användare, vidta rättslig åtgärd, utföra böter/vite mot
              eller radera konton för personer som missbrukar tjänsten på
              följande sätt; <strong>a)</strong> Försöker Handla eller ta del av
              Tjänster under annan Användares namn och eller Konto utan dennes
              medgivande <strong>b)</strong> Försök att härma/kopiera, modifiera
              eller skapa derivativa verk av NearbyStores App, webbsida
              (www.nearbystore.se, med alla dess subdomäners innehåll,
              (“Webbsida”)) eller andra delar av våra Tjänster eller relaterad
              mjukvara/teknologi. <strong>c)</strong> Utöva reverse engineering,
              dekompilera, demontera, dechiffrera eller på annat visa försöka
              lista ut hur våra Tjänster, teknologier eller mjukvara är
              uppbyggd/skriven. <strong>d)</strong> På något sätt försöker täcka
              över/skymma eller utföra borttagning av annonser eller reklam i
              vår App, Webbsida eller andra tillhörande delar av Tjänsterna vi
              erbjuder. <strong>e)</strong> Använda botar, datorprogram,
              algoritmer eller andra automatiserade metoder för att använda vår
              App, Webbsida eller Tjänster. <strong>f)</strong> Skapa konton,
              Handla eller använda våra Tjänster under annans eller falsk
              identitet. <strong>g)</strong> samla in, kopiera, nyttja eller
              föra över information som erhållits genom vår App, Webbsida eller
              någon av våra Tjänster utan ett skriftligt tillstånd av
              NearbyStore <strong>h)</strong> skaffa åtkomst till våra Tjänster
              eller att Handla genom andra sätt än via vår Webbsida, App eller
              Beställningsskärm.
            </p>
            <br />
            <p>
              Dessa Användarvillkor utgör ett bindande avtal mellan NearbyStore
              och Användaren under hela den tidsperiod som Användaren använder
              NearbyStores Tjänster eller Handlar från NearbyStore, antingen via
              Appen, Webbsidan eller Beställningsskärmen.
            </p>
            <br />
            <p>
              Användaren kan avsluta sitt användande av våra Tjänster eller
              avstå att Handla närsomhelst. Tillika kan NearbyStore välja att
              närsomhelst upphöra att erbjuda dessa Tjänster eller möjligheten
              att handla, tillfälligt eller permanent.
            </p>
            <h3 class="pt-5">12. Ändringar</h3>
            <p>Användarvillkoren får enbart ändras av NearbyStore.</p>
            <br />
            <p>
              Vid ändring eller tillägg i Användarvillkoren ska NearbyStore
              förmedla detta när registrerade Kunder gör ett nytt Köp och/eller
              i utskick till kunder med ett registrerat konto hos NearbyStore.
            </p>
            <br />
            <p>
              De kunder som handlar utan att ha ett konto omfattas av de för
              Köptillfället gällande Användaravtalet och godkänner i och med Köp
              detta Avtal.
            </p>
            <h3 class="pt-5">13. Överlåtelse</h3>
            <p>
              NearbyStore har rätt att utan Användarens samtycke helt eller
              delvis ge äganderätten av alla eller delar av sina rättigheter och
              åtaganden under dessa Användarvillkor till;
              <strong>a)</strong> Juridiska personer eller bolag inom samma
              koncern, <strong>b)</strong> Efterträdare som tar över
              NearbyStores plattform <strong>c)</strong> Genom uppgörelse,
              sammanslagning eller annat förvärv av aktier, inkråm eller
              rättigheter relaterat till NearbyStores plattform och/eller
              Tjänster.
            </p>
            <br />
            <p>
              Användaren har inte rätt att frånsäga sig eller överlåta några av
              sina rättigheter eller skyldigheter, helt eller delvis, i enlighet
              med detta Avtal.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts"></script>
<style></style>
