<template>
  <v-row>
    <v-col>
      <v-col>
        <p class="bold-h2">
          {{ featuredText }}
        </p>
      </v-col>

      <v-row
        justify="center"
        align="center"
        class="pa-0 mt-1"
        style="height: 330px; overflow: hidden"
      >
        <v-col sm="12" lg="11" xl="10" class="pa-4">
          <v-row justify=" " align="center" class="pa-0">
            <v-col
              class="custom5cols pa-0"
              v-for="product in products"
              :key="product._id"
            >
              <ProductCard
                :product="product"
              ></ProductCard> </v-col></v-row></v-col
      ></v-row>
      <v-row align="center" justify="center" v-if="products.length > 5">
        <v-col sm="11">
          <v-btn
            @click="allProductsClicked"
            flat
            block
            size="large"
            class="button text-none"
          >
            {{ $t("show_all") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DiscountIcon from "../assets/svg/discount.vue";
import ProductCard from "./ProductCard.vue";
export default {
  name: "FeaturedProducts",
  components: {
    // DiscountIcon is a custom SVG component for displaying discount tags.
    DiscountIcon,
    ProductCard,
  },
  props: {
    // An array of products to be displayed in the featured products section.
    products: {
      type: Array,
      required: true,
    },
    // Text to be displayed as the featured section header.
    featuredText: {
      type: String,
      default: "",
    },
    offers: {
      type: Boolean,
      default: false,
    },
    // Unique identifier for the banner, used for tracking and analytics.
    bannerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Indicates if the scroll is at the leftmost position.
      scrolledToLeft: true,
      // Indicates if the scroll is at the rightmost position.
      scrolledToRight: false,
      // Determines if the scroll buttons should be displayed.
      canScroll: false,
      firstLoad: true,
    };
  },
  methods: {
    /**
     * Dispatches an action when the 'all products' button is clicked.
     */
    allProductsClicked() {
      // Redirect to the URL with bannerId when "all products" is clicked
      this.offers
        ? this.$router.push({
            name: "offers",
          })
        : this.$router.push({
            name: "textBanner",
            params: {
              id: this.bannerId,
            },
          });
    },
  },
};
</script>

<style scoped>
.custom5cols {
  width: 20%;
  max-width: 200px;
  min-width: 180px;
  flex-basis: 20%;
}
.control-container {
  display: flex;
  margin: 20px;
  margin-left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.button-container {
  display: flex;
  align-items: center;
}

.scroll-buttons {
  display: flex;
  margin-left: 10px;
}

.featured-products-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1300px;
  position: relative;
  overflow: hidden;
}

.product-scroll-row {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  scroll-snap-type: x mandatory;
  padding-bottom: 5px;
}

.product-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  scroll-snap-align: start;
  min-width: 236px;
}

.product-image-container {
  position: relative;
  padding: 10px 20px;
}

.featured-text {
  color: black;
  padding: 5px;
  font-weight: regular;
  font-size: 32px;
  z-index: 1;
}

.offer-tag {
  position: absolute;
  top: -35px;
  right: -40px;
  padding: 5px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

.discount-icon {
  width: 135px;
  height: 100px;
  position: relative;
}

.product-image {
  width: 100%;
  height: 150px;
  margin-top: 20px;
  object-fit: contain;
}

.product-details {
  padding: 10px;
  text-align: left;
}

.product-name,
.product-weight,
.product-price {
  color: #333;
  margin: 4px 0;
  text-align: left;
}

.product-weight {
  color: #777;
}

.product-name {
  font-size: 18px;
}

.product-price {
  font-size: 22px;
  color: #c94631;
  font-weight: bold;
  margin: 4px 0;
}

.add-to-cart-btn {
  background-color: #003a70;
  color: white;
  border: none;
  width: 95%;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 10px;
  height: 50px;
}

.add-to-cart-btn:hover {
  background-color: #005092;
}

.scroll-btn {
  width: 45px;
  height: 30px;
  position: relative;
  background-color: white;
  font-weight: bold;
  color: black;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.scroll-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid black;
}

.scroll-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 1px solid black;
}

.all-products-btn {
  color: #003a70;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: regular;
  font-size: 25px;
}

.item-amount-container {
  user-select: none;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: center;
  background-color: #003a700f;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  height: 50px;
}

.item-amount {
  font-size: 18px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.remove-cart,
.add-cart {
  margin: 5px;
  min-width: 35px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.remove-cart {
  border: solid;
  border-width: 1px;
  background-color: white;
}

.add-cart {
  height: 50px;
  background-color: #003a70;
  color: white;
}

/* Phone styles*/

@media (max-width: 425px) {
  .all-products-btn {
    display: none;
  }

  .featured-text {
    text-decoration: underline;
    color: #003a70;
  }
}
</style>
