<template>
  <div class="list-row">
    <div class="control-container">
      <h2 class="section-title">{{ title }}</h2>
      <div class="button-container">
        <button
          v-if="!(likedLists && lists.length === 0)"
          class="all-products-btn"
          @click="allProductsClicked"
        >
          {{ $t("show_all") }}
        </button>
        <div v-if="canScroll" class="scroll-buttons">
          <button
            class="scroll-btn scroll-left"
            :style="leftButtonStyle"
            @click="scroll(-1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </button>
          <button
            class="scroll-btn scroll-right"
            :style="rightButtonStyle"
            @click="scroll(1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="lists-container" ref="scrollRow" @scroll="checkScrollPosition">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else-if="lists.length === 0" class="no-lists">
        <button class="add-list-button" @click="handleAddListClick">
          <v-icon>{{ likedLists ? "mdi-magnify" : "mdi-plus" }}</v-icon>
        </button>
      </div>
      <div v-else class="horizontal-slider">
        <ListCard
          v-for="list in lists"
          :key="list._id"
          :list="list"
          :likedLists="likedLists"
          @navigate-to-list="$emit('navigate-to-list', list._id)"
          @list-unliked="handleListUnliked"
        />
        <AddNewListCard
          @show-create-list-modal="handleAddListClick"
          :iconType="likedLists ? 'magnify' : 'plus'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListCard from "@/components/ListCard.vue";
import AddNewListCard from "@/components/AddNewListCard.vue";

export default {
  name: "ListRow",
  components: {
    ListCard,
    AddNewListCard,
  },
  props: {
    lists: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    likedLists: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolledToLeft: true,
      scrolledToRight: false,
      canScroll: false,
    };
  },
  methods: {
    scroll(direction) {
      const container = this.$refs.scrollRow;
      const scrollAmount =
        container.querySelector(".list-card").clientWidth + 20;
      container.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    },
    checkScrollPosition() {
      const container = this.$refs.scrollRow;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      this.scrolledToLeft = container.scrollLeft === 0;
      this.scrolledToRight = container.scrollLeft >= maxScrollLeft - 1;
      this.canScroll = container.scrollWidth > container.clientWidth;
    },
    allProductsClicked() {
      this.$router.push({
        path: this.likedLists ? "/saved/liked" : "/saved/lists",
      });
    },
    handleAddListClick() {
      if (this.likedLists) {
      } else {
        this.$emit("show-create-list-modal");
      }
    },
    handleListUnliked(listId) {
      this.$emit(
        "update-lists",
        this.lists.filter((list) => list._id !== listId)
      );
    },
  },
  computed: {
    leftButtonStyle() {
      return {
        color: this.scrolledToLeft ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
    rightButtonStyle() {
      return {
        color: this.scrolledToRight ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScrollPosition();
      window.addEventListener("resize", this.checkScrollPosition);
    });
  },
  updated() {
    this.$nextTick(() => {
      this.checkScrollPosition();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScrollPosition);
  },
};
</script>

<style scoped>
.list-row {
  margin-bottom: 20px;
}

.control-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-title {
  font-weight: normal;
  font-size: 32px;
}

.button-container {
  display: flex;
  align-items: center;
}

.scroll-buttons {
  display: flex;
  margin-left: 10px;
}

.lists-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.horizontal-slider {
  display: flex;
  gap: 20px;
  width: 100%;
  scroll-snap-align: start;
}

.no-lists {
  width: 100%;
}

.add-list-button {
  height: 250px;
  width: 100%;
  border: 2px dashed darkgrey;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: darkgrey;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  scroll-snap-align: start;
}

.add-list-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
}

.all-products-btn {
  color: #003a70;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: regular;
  font-size: 25px;
}

.scroll-btn {
  width: 45px;
  height: 30px;
  position: relative;
  background-color: white;
  font-weight: bold;
  color: black;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.scroll-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid black;
}

.scroll-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 1px solid black;
}
</style>
