import store from "./store";

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function formatTime(inDate) {
  const date = new Date(inDate);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}
export function getDayLabel(inDate, withDate) {
  const date = new Date(inDate);
  const today = new Date();

  // Remove time component from both dates to compare only the date part

  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const inputDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const diffTime = inputDate - todayDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    return this.$t("today") + ", " + (withDate ? this.$d(date, "short") : "");
  } else if (diffDays === 1) {
    return (
      this.$t("tomorrow") + ", " + (withDate ? this.$d(date, "short") : "")
    );
  } else {
    return capitalizeFirstLetter(this.$d(date, "long"));
  }
}
