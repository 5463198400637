<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
    color="primary"
  ></v-progress-linear>
  <div v-else>
    <SubHeader />
    <CategoryNavigation class="mt-4 pb-2" />
    <router-view v-slot="{ Component }">
      <v-container class="pa-0 mt-8" style="min-height: 100vh">
        <CartModal />

        <v-container class="pa-0 ma-0" ref="mainContent">
          <KeepAlive>
            <component :is="Component"> </component>
          </KeepAlive>
        </v-container>
      </v-container>
    </router-view>
    <router-view v-slot="{ Component }" name="dialog">
      <div class="modal-container" :class="{ 'is-active': Component }">
        <div class="modal">
          <component :is="Component" />
        </div>
      </div>
    </router-view>
  </div>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import store from "@/store";
import { mapFields } from "vuex-map-fields";
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapFields(["mainContentWidth"]),
  },

  async mounted() {
    // Create a ResizeObserver to monitor changes in the size of the mainContent
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        // Update the contentWidth when the size changes
        this.mainContentWidth = entry.contentRect.width;
      }
    });

    // Start observing the main content element

    await store.dispatch("initializeApp");
    this.loading = false;
    setTimeout(() => {
      resizeObserver.observe(this.$refs.mainContent.$el);
    }, 1000);
  },
};
</script>
