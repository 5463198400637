<template>
  <v-navigation-drawer
    v-model="locationModalIsVisible"
    temporary
    location="right"
    :width="$vuetify.display.xs || $vuetify.display.sm ? 0 : 380"
    :style="!$vuetify.display.xs ? 'width: 380px' : 'width: 100%'"
  >
    <v-card height="100%" class="pt-5" style="overflow-y: auto">
      <v-row>
        <v-col class="pa-4">
          <v-row
            align="start"
            :justify="step > 1 ? 'space-between' : 'end'"
            class="pa-0"
          >
            <v-btn
              v-if="step > 1"
              class="ma-2"
              elevation="0"
              @click="step--"
              icon="mdi-arrow-left"
              color="surface"
              size="small"
              aria-label="Vänster"
            ></v-btn>
            <v-btn
              class="ma-2"
              elevation="0"
              @click="openLocationSelection"
              icon="mdi-close"
              color="surface"
              size="small"
              aria-label="Stäng"
            ></v-btn>
          </v-row>
          <v-window disabled v-model="step">
            <v-window-item disabled :value="1">
              <v-col class="pa-0" align="center">
                <p class="bold-h3">{{ $t("pickup_locations") }}</p>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col cols="10" class="pa-0">
                    <searchAddress @updateCoords="updateCoords" />
                  </v-col>
                </v-row>

                <v-row cols="12" class="pa-2">
                  <v-col
                    cols="6"
                    sm="6"
                    class="pa-2"
                    v-for="location in pickupPoints"
                    :key="location._id"
                  >
                    <v-card
                      @click="selectPickupLocation(location._id)"
                      style="height: 100%"
                      class="pa-2 locker"
                      elevation="0"
                      :style="{
                        border:
                          cart.pickupPoint === location._id
                            ? '2px solid #4CAF50'
                            : '2px solid grey',
                      }"
                    >
                      <v-col class="pa-0" align="start">
                        <v-col
                          cols="12"
                          align="center"
                          justify="center"
                          class="pa-0"
                        >
                          <v-img :src="locker" contain alt="ns-locker"></v-img>
                        </v-col>
                        <p class="bold-h4">
                          {{ location.name }}
                        </p>
                        <p class="body-2 pt-2">{{ location.address }}</p>
                        <p v-if="lat > 0" class="body-2">
                          Avstånd:
                          {{ writeDistance(location.distanceInMeters) }}
                        </p>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="2">
              <v-col class="pa-0" align="center">
                <p class="bold-h3">{{ $t("pickup_times") }}</p>
                <p>
                  {{
                    this.pickupPoints.find(
                      (e) =>
                        e._id ==
                        (this.selectedPickupLocation != ""
                          ? this.selectedPickupLocation
                          : cart.pickupPoint)
                    )?.name ?? ""
                  }}
                </p>
                <v-progress-circular
                  v-if="pickupTimesLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-col v-else class="pa-0 mt-2">
                  <v-window v-model="window" show-arrows>
                    <template v-slot:prev="{ props }">
                      <v-btn size="large" @click="props.onClick" variant="text">
                        <v-icon size="30">mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:next="{ props }">
                      <v-btn size="large" @click="props.onClick" variant="text">
                        <v-icon size="30">mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <v-window-item
                      v-for="(date, index) in next14Days"
                      :key="index"
                      :value="index"
                    >
                      <v-card
                        elevation="0"
                        class="d-flex justify-center align-center"
                      >
                        <span class="bold-h4 mb-3">
                          {{ getDayLabel(date, true) }}</span
                        >
                      </v-card>
                    </v-window-item>
                  </v-window>
                  <div v-for="(date, index) in next14Days">
                    <v-col v-if="window === index">
                      <v-row class="mt-2">
                        <v-col
                          v-if="
                            pickupTimes.filter(
                              (element) =>
                                isSameDate(element.orderBefore, date) === true
                            ).length > 0
                          "
                          cols="6"
                          sm="6"
                          class="pa-1"
                          v-for="(time, index) in pickupTimes.filter(
                            (element) =>
                              isSameDate(element.orderBefore, date) === true
                          )"
                        >
                          <v-card
                            @click="choosePickupTime(time)"
                            class="pa-2 locker"
                            elevation="0"
                            :style="{
                              border:
                                cart.pickupTime != null &&
                                cart.pickupTime._id === time._id
                                  ? '2px solid #4CAF50'
                                  : '2px solid grey',
                            }"
                            ><v-col>
                              <p class="font-weight-bold">
                                {{
                                  formatTime(time.pickupAt) +
                                  " - " +
                                  formatTime(time.pickupBefore)
                                }}
                              </p>
                              <p>{{ time.price.toFixed(2) + " kr" }}</p>
                              <p class="font-italic">
                                {{
                                  $t("order_by") +
                                  " " +
                                  formatTime(time.orderBefore)
                                }}
                              </p>
                            </v-col>
                          </v-card>
                        </v-col>
                        <v-col v-else> {{ $t("no_available_times") }}</v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <v-col class="continue pa-2 mt-2 pl-8 pr-8" v-if="step == 1">
        <v-btn
          block
          class="pa-2 xl-button text-none"
          color="primary"
          @click="seeAllLocations"
        >
          {{ $t("see-all-pickup-locations") }}
        </v-btn>
      </v-col>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import locker from "../../assets/images/ns-locker.png";
import PickupTimeAPI from "@/api/PickupTimeAPI"; // Import the UserAPI
import { formatTime, getDayLabel } from "../../utils.js";
import PickupPointAPI from "@/api/PickupPointAPI";
import { useGeolocation } from "@vueuse/core";
import searchAddress from "../searchAddress.vue";

export default {
  components: {
    searchAddress,
  },
  setup() {
    const { coords, locatedAt, error, resume, pause } = useGeolocation({
      immediate: false, // Prevents immediate triggering
    });
    return { coords, locatedAt, error, resume, pause };
  },

  computed: {
    ...mapFields(["locationModalIsVisible", "cart"]),
  },
  watch: {
    async locationModalIsVisible(newVal, oldVal) {
      if (newVal == true) {
        if (this.cart.pickupPoint != null) {
          this.step = 2;
          this.selectedPickupLocation = this.cart.pickupPoint;
          const response = await PickupTimeAPI.getAllPickupTimes(
            this.selectedPickupLocation
          );
          this.generateNext14Days();
          this.window = 0;
          this.pickupTimes = response.data;
          this.pickupTimesLoading = false;
          if (this.cart.pickupTime != null) {
            this.window = this.calculateActualDaysFromToday(
              this.cart.pickupTime.orderBefore
            );
          }
        }
        const resp = await PickupPointAPI.getAllPickupPoints({
          lat: this.lat,
          lng: this.lng,
        });
        this.pickupPoints = resp.data;
      }
    },
  },
  async mounted() {
    const resp = await PickupPointAPI.getAllPickupPoints({
      lat: this.lat,
      lng: this.lng,
    });
    this.pickupPoints = resp.data;
    if (this.cart.pickupPoint != null) {
      this.step = 2;
      this.selectedPickupLocation = this.cart.pickupPoint;
      const response = await PickupTimeAPI.getAllPickupTimes(
        this.selectedPickupLocation
      );
      this.generateNext14Days();
      this.window = 0;
      this.pickupTimes = response.data;
      this.pickupTimesLoading = false;
    }
  },
  created() {
    this.generateNext14Days();
  },
  methods: {
    ...mapActions([
      "selectLocation",
      "toggleLocationModal",
      "updateSelectedPickupTime",
    ]),
    formatTime,
    getDayLabel,
    calculateActualDaysFromToday(date) {
      const today = new Date();
      const orderBefore = new Date(date);

      // Reset time to midnight for both dates to focus only on the day comparison
      today.setHours(0, 0, 0, 0);
      orderBefore.setHours(0, 0, 0, 0);

      // Calculate the time difference in milliseconds
      const timeDiff = orderBefore.getTime() - today.getTime();

      // Convert the time difference from milliseconds to days
      const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));

      return dayDiff;
    },
    seeAllLocations() {
      this.openLocationSelection();
      this.$router.push({
        name: "pickupPoints",
      });
    },
    async updateCoords(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      const resp = await PickupPointAPI.getAllPickupPoints({
        lat: lat,
        lng: lng,
      });
      this.pickupPoints = resp.data;
    },
    writeDistance(distanceInM) {
      if (distanceInM < 100) {
        return "Under 100m";
      } else if (distanceInM < 1000) {
        return distanceInM.toFixed(0) + "m";
      } else {
        return (distanceInM / 1000).toFixed(1) + " km";
      }
    },
    generateNext14Days() {
      const days = [];
      const today = new Date();
      for (let i = 0; i < 14; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        days.push(nextDay);
      }
      this.next14Days = days;
    },

    isSameDate(inDate, checkDate) {
      const date = new Date(inDate);
      const today = new Date(checkDate);

      // Remove time component from both dates to compare only the date part
      const todayDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const inputDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      const diffTime = inputDate - todayDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        return true; // "Today" in Swedish
      } else {
        false;
      }
    },
    async choosePickupTime(pickupTime) {
      const location = this.pickupPoints.find(
        (loc) => loc._id === pickupTime.pickUpPoint
      );

      if (location) {
        this.selectedPickupLocation = location._id;
        await this.selectLocation({
          storeId: location.warehouseId,
          pickupLocation: this.selectedPickupLocation,
          pickupTime: pickupTime,
        });
      }
      this.toggleLocationModal();
    },

    openLocationSelection() {
      this.toggleLocationModal();
    },
    /**
     * Selects a pickup location.
     * @param {string} locationId - The ID of the location to select.
     */
    async selectPickupLocation(locationId) {
      this.step = 2;
      this.selectedPickupLocation = locationId;
      const response = await PickupTimeAPI.getAllPickupTimes(locationId);
      this.generateNext14Days();
      this.window = 0;
      this.pickupTimes = response.data;
      this.pickupTimesLoading = false;
    },
  },
  data() {
    return {
      selectedPickupLocation: "",
      scrolledToLeft: true,
      scrolledToRight: false,
      pickupTimes: {},
      pickupPoints: [],
      pickupTimesLoading: true,
      step: 1,
      model: null,
      next14Days: [],
      lng: 0,
      lat: 0,
      activeTab: null,
      length: 3,
      locker: locker,
      window: 0,
    };
  },
};
</script>

<style>
.continue {
  position: sticky;
  bottom: 10px;
  z-index: 100;
}
</style>
