import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store

/**
 * API for interacting with shopping list-related endpoints.
 */
const ShoppingListAPI = {
  /**
   * Gets all shopping lists.
   *
   * @param {Object} params - Parameters for the request.
   * @param {number} [params.limit=50] - The number of shopping lists to retrieve.
   * @param {number} [params.page=1] - The page number to retrieve.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the shopping lists.
   */
  getAllShoppingLists: ({ limit = 50, page = 1 } = {}) => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    if (!storeId) {
      throw new Error("Store ID not found");
    }

    return axiosInstance.get("/shoppingList", {
      headers: {
        storeId,
      },
      params: {
        limit,
        page,
      },
    });
  },

  /**
   * Creates a new shopping list.
   *
   * @param {Object} shoppingList - The shopping list to be created.
   * @param {string} shoppingList.name - The name of the shopping list.
   * @param {Array<string>} shoppingList.products - The products to be included in the shopping list.
   * @param {boolean} shoppingList.onlineAvailability - The online availability of the shopping list.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the created shopping list.
   */
  createShoppingList: (shoppingList) => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    const payload = {
      ...shoppingList,
      userId,
    };

    return axiosInstance.post("/shoppingList", payload, {
      headers: {
        storeId,
      },
    });
  },

  /**
   * Gets the user's liked shopping lists.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the liked shopping lists.
   */
  getUserLikedShoppingLists: () => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    return axiosInstance.get(`/shoppingList/user/liked/${userId}`, {
      headers: {
        storeId,
      },
    });
  },

  /**
   * Gets the user's own shopping lists.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the user's own shopping lists.
   */
  getUserOwnShoppingLists: () => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    return axiosInstance.get(`/shoppingList/user/${userId}`, {
      headers: {
        storeId,
      },
    });
  },

  /**
   * Gets a specific shopping list.
   *
   * @param {string} id - The ID of the shopping list to retrieve.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the specific shopping list.
   */
  getShoppingListById: (id) => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    if (!storeId) {
      throw new Error("Store ID not found");
    }

    return axiosInstance.get(`/shoppingList/${id}`, {
      headers: {
        storeId,
      },
    });
  },

  /**
   * Updates a specific shopping list.
   *
   * @param {string} id - The ID of the shopping list to update.
   * @param {Object} shoppingList - The shopping list data to update.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the updated shopping list.
   */
  updateShoppingList: (id, shoppingList) => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    const payload = {
      ...shoppingList,
      userId,
    };

    return axiosInstance.patch(`/shoppingList/${id}`, payload, {
      headers: {
        storeId,
      },
    });
  },

  /**
   * Deletes a specific shopping list.
   *
   * @param {string} id - The ID of the shopping list to delete.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response confirming the deletion.
   */
  deleteShoppingList: (id) => {
    return axiosInstance.delete(`/shoppingList/${id}`);
  },

  /**
   * Likes or unlikes a shopping kit.
   *
   * @param {Object} data - The data for the request.
   * @param {boolean} data.like - Whether to like or unlike the shopping kit.
   * @param {string} data.shoppingKitId - The ID of the shopping kit to like or unlike.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the updated user information.
   */
  likeShoppingKit: (data) => {
    const storeId = store.state.location.storeId || ""; // Fetch storeId from Vuex store
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!storeId) {
      throw new Error("Store ID not found");
    }

    if (!userId) {
      throw new Error("User ID not found");
    }

    return axiosInstance.post(`/user/like/shoppingKit/${userId}`, data, {
      headers: {
        storeId,
      },
    });
  },

  likeRecipe: async (data) => {
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!userId) {
      throw new Error("User ID not found");
    }

    const resp = await axiosInstance.post(`/user/like/recipe/${userId}`, data);
    return resp.data;
  },

  getUserLikedRecipes: async () => {
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!userId) {
      throw new Error("User ID not found");
    }
    const resp = await axiosInstance.get(`/recipe/user/liked/${userId}`);
    return resp.data;
  },
};

export default ShoppingListAPI;
