// PickupTimeAPI.js
import axiosInstance from "./AxiosInstance";
import store from "@/store";

/**
 * API for interacting with pickupTime related endpoints.
 */
const RecipeAPI = {
  /**
   * Retrieves all pickup times based on predefined parameters.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the pickup times.
   */
  fetchRecipes: ({ limit = 50, page = 1 } = {}) => {
    const params = {
      limit,
      page,
    };
    return axiosInstance.get("/recipe");
  },
  fetchRecipe: (id) => {
    return axiosInstance.get("/recipe/" + id);
  },
  createRecipe: (data) => {
    return axiosInstance.post("/recipe", data);
  },
  getCreatedRecipe: () => {
    const userId = store.state.user._id; // Fetch userId from Vuex store

    if (!userId) {
      throw new Error("User ID not found");
    }
    return axiosInstance.get(`/recipe/user/${userId}`);
  },
  postImage: (id, formData) => {
    return axiosInstance.post("/recipe/uploadPhoto/" + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure the correct content type
      },
    });
  },
};

export default RecipeAPI;
