<template>
  <!-- Main container for the banner products page -->
  <v-row align="center" justify="center" v-if="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-row>

  <div class="grid-container" v-else>
    <!-- Vuetify Loading Component -->

    <!-- Content to display when not loading -->
    <div>
      <!-- Displaying the banner with the banner image from the API -->

      <BannerContainer
        :imageUrl="banner.mainImage?.url || banner.image?.url || ''"
      />
      <v-col> <Breadcrumbs :items="breadcrumbItems" /></v-col>

      <!-- Displaying all products associated with the banner -->
      <v-col>
        <p class="bold-h1">{{ banner.title }}</p>
        <p class="body-2 mb-10">
          {{ banner.description != null ? banner.description : "" }}
        </p>
        <v-row align="center" justify="center"> </v-row>
        <AllProducts :products="products" :hideLoadMore="true" />
      </v-col>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import BannerContainer from "../ProductBanner.vue";
import AllProducts from "../AllProducts.vue";
import BannerAPI from "@/api/BannerAPI";
import { useI18n } from "vue-i18n";
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState } from "vuex";
import { useHead } from "@unhead/vue";
/**
 * BannerProducts component.
 * Displays products associated with a specific banner.
 *
 * @component
 */
export default {
  name: "BannerProducts",
  components: {
    BannerContainer,
    AllProducts,
    Breadcrumbs,
  },
  data() {
    return {
      banner: null,
      loading: true,
      products: [],
    };
  },
  watch: {
    currentLang(newVal) {
      this.fetchBanner();
    },
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  computed: {
    ...mapState(["currentLang", "categories"]),
    title() {
      return this.subCategory != null
        ? this.subCategory.category.name
        : this.category.category.name;
    },
    breadcrumbItems() {
      return [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        { text: this.banner.title, disabled: true },
      ];
    },
  },
  methods: {
    async fetchBanner() {
      const bannerId = this.$route.params.id;
      const response = await BannerAPI.getBannerById(bannerId);
      this.banner = response.data;
      this.setHead();
      this.products = response.data.products || [];
    },
    setHead() {
      const bannerTitle = `${this.banner.title} - NearbyStore`;

      useHead({
        title: bannerTitle,
        meta: [
          {
            property: "og:image",
            content: this.banner.mainImage
              ? this.banner.mainImage.url
              : this.banner.image.url,
          },
          {
            property: "og:url",
            content: window.location.href, // Automatically use current page URL
          },
          {
            property: "og:title",
            content: bannerTitle, // Ensure Open Graph title matches
          },
          {
            hid: "description",
            name: "description",
            content:
              "Beställ idag på NearbyStore och få din mat levererad samma dag. Upptäck ett brett sortiment av färska råvaror och livsmedel, med bekväm hämtning nära dig. Shoppa enkelt och snabbt!",
          },
        ],
        __key: "bannerDetailHead",
      });
    },
  },
  async activated() {
    this.loading = true;
    await this.fetchBanner();
    this.loading = false;
  },
};
</script>

<style scoped>
/* Style for breadcrumbs container */
.breadcrumbs {
  max-width: 1300px;
  margin: auto;
  padding-left: 20px;
}

/* Style for the main grid container */
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}
</style>
