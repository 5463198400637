<template>
  <div class="banner-container">
    <!-- Display only the passed-in image if imageUrl prop is provided -->
    <v-col v-if="imageUrl" cols="12" lg="10" xl="12" class="pa-0">
      <v-img
        :aspect-ratio="5 / 2"
        cover
        :src="imageUrl"
        alt="Image banner"
        class="rounded-lg mr-5 ml-5"
      />
    </v-col>
    <!-- Otherwise, display the slideshow of fetched banners -->
    <v-row v-else-if="banners.length > 0" justify="center">
      <v-col cols="12" lg="10" xl="12">
        <v-carousel
          show-arrows="hover"
          height="auto"
          cycle
          hide-delimiters
          hide-delimiter-background
        >
          <v-carousel-item v-for="(banner, i) in banners" :key="i">
            <v-img
              @click="navigateToBanner(banner._id)"
              :aspect-ratio="5 / 2"
              cover
              :src="banner.mainImage?.url || banner.image?.url"
              :alt="banner.image.altText"
              class="rounded-lg hover"
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BannerAPI from "@/api/BannerAPI"; // Import Banner API

/**
 * BannerContainer component for displaying and navigating through banner images.
 * If a specific image URL is provided as a prop, it displays only that image.
 * Otherwise, it fetches and displays a slideshow of banners from the API.
 */
export default {
  name: "BannerContainer",

  /**
   * Props for the component.
   * @prop {String} imageUrl - An optional URL for a specific banner image.
   */
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
  },

  /**
   * Data properties of the component.
   */
  data() {
    return {
      banners: [], // Array to store banner data
      currentBannerIndex: 0, // Index of the current banner
    };
  },

  /**
   * Computed properties for the component.
   */
  computed: {
    /**
     * Computes the URL of the current banner image.
     * @returns {String} URL of the current banner image.
     */
    currentBannerImage() {
      return this.banners[this.currentBannerIndex].image.url;
    },

    /**
     * Computes the alternative text of the current banner image.
     * @returns {String} Alt text of the current banner image.
     */
    currentBannerAltText() {
      return this.banners[this.currentBannerIndex].image.altText;
    },
  },

  /**
   * Lifecycle hook for fetching banners when the component is created.
   */
  async created() {
    // Fetch banners only if imageUrl prop is not provided
    if (!this.imageUrl) {
      try {
        const response = await BannerAPI.getAllBanners({
          imageBanner: true,
          textBanner: false,
        });
        if (response.data && response.data.length > 0) {
          this.banners = response.data;
        } else {
          console.warn("No banners returned from the API.");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }

      this.startSlideshow();
    }
  },

  /**
   * Methods of the component.
   */
  methods: {
    /**
     * Starts an automated slideshow of banners.
     */
    startSlideshow() {
      setInterval(() => {
        this.currentBannerIndex =
          (this.currentBannerIndex + 1) % this.banners.length;
      }, 10000); // Change banner every 10 seconds
    },

    /**
     * Navigates to the previous banner image.
     */
    navigateLeft() {
      this.currentBannerIndex =
        this.currentBannerIndex === 0
          ? this.banners.length - 1
          : this.currentBannerIndex - 1;
    },

    /**
     * Navigates to the next banner image.
     */
    navigateRight() {
      this.currentBannerIndex =
        (this.currentBannerIndex + 1) % this.banners.length;
    },

    /**
     * Navigates to the banner detail page.
     */
    navigateToBanner(id) {
      this.$router.push({
        name: "banner",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.hover {
  cursor: pointer;
}
.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* For absolute positioning of buttons */
  max-width: 1300px;
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 32px;
}

.banner-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.banner-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.multiple-images {
  cursor: pointer;
}

.nav-btn {
  position: relative;
  border-radius: 25px;
  height: 50px;
  width: 50px;
  background-color: #003a70;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}

.left-btn {
  left: 25px;
}

.right-btn {
  right: 25px;
}

@media (max-width: 425px) {
  .nav-btn {
    display: none;
  }

  .banner-container {
    margin-bottom: 0px;
  }
}
</style>
