<template>
  <v-dialog v-model="show" @click:outside="decline" width="800">
    <v-card height="600" color="background">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-row justify="space-between">
              <p class="bold-h1 pa-2">Byt Produkt</p>
              <v-btn
                elevation="0"
                @click="decline"
                variant="text"
                icon="mdi-close"
                size="x-large"
                aria-label="Stäng"
              ></v-btn
            ></v-row>

            <v-card color="surface" elevation="0" class="pa-2 mt-6">
              <v-row align="center">
                <v-col
                  cols="2"
                  align="center"
                  justify="center"
                  @click="handleProductClick(originalProduct)"
                  class="hover"
                  style="position: relative"
                >
                  <DiscountPrice
                    v-if="originalProduct.promotion"
                    style="
                      position: absolute;
                      top: 3px;
                      right: 0px;
                      z-index: 1000;
                    "
                    :size="{ width: 40, height: 40 }"
                    :promotion="originalProduct.promotion"
                    :isWeight="originalProduct.weightProduct"
                  />
                  <v-img
                    :src="originalProduct.thumbnail.url"
                    alt="Product Image"
                    height="70"
                  />
                </v-col>
                <v-col justify="center">
                  <p class="bold-1">
                    {{ originalProduct.name }}
                  </p>
                  <p class="body-4">
                    {{ originalProduct.manufacturer }}
                  </p>
                </v-col>

                <v-col class="pa-4" align="end">
                  <p class="bold-h4 mr-4">
                    {{
                      (originalProduct.price != undefined
                        ? originalProduct.price.toFixed(2)
                        : "") + " kr"
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <v-col class="pa-0 mt-4" cols="8">
              <v-text-field
                class="mt-0 rounded-circle"
                v-model="search"
                v-on:keyup.enter="handleSearch($event)"
                color="primary"
                variant="outlined"
                single-line
                hide-details
                dense
                @focus="onFocus"
                bg-color="surface"
                prepend-inner-icon="mdi-magnify"
                :label="$t('search_item') + '...'"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="products && products.length"
              class="pl-4 pr-4 pt-2 mt-6 justify-center"
            >
              <v-row justify="center" align="center" class="pa-0">
                <v-col sm="12" lg="11" xl="10" class="pa-0">
                  <v-row justify="center" align="center" class="pa-0">
                    <v-col
                      class="custom5cols pa-0"
                      v-for="product in products"
                      :key="product._id"
                    >
                      <v-card elevation="0" class="ma-2">
                        <v-col>
                          <DiscountPrice
                            v-if="product.promotion"
                            style="
                              position: absolute;
                              top: 2px;
                              right: 2px;
                              z-index: 1000;
                            "
                            :promotion="product.promotion"
                            :isWeight="product.weightProduct"
                          />

                          <v-container
                            @click="handleProductClick(product)"
                            class="hover"
                          >
                            <v-img
                              v-if="product.thumbnail"
                              class="text-center"
                              :src="product.thumbnail.url"
                              :alt="product.thumbnail.alt"
                              height="80"
                            />
                            <v-progress-circular
                              v-if="!product.thumbnail"
                              color="blue"
                              height="10"
                              indeterminate
                            />
                          </v-container>

                          <v-row
                            align="start"
                            justify="space-between"
                            class="pa-2 justify-space-between"
                            style="height: 170px"
                          >
                            <v-col class="pa-0" sm="12">
                              <p class="bold-1">{{ product.name }}</p>
                              <p class="body-4">
                                {{
                                  product.manufacturer +
                                  " " +
                                  product.displayWeight
                                }}
                              </p>
                              <div
                                class="bold-2"
                                v-if="
                                  product.weightProduct &&
                                  product.weightInKg != null
                                "
                              >
                                <p
                                  v-if="
                                    product.promotion &&
                                    product.promotion.minQuantity == 1
                                  "
                                  style="color: #c94631"
                                >
                                  {{
                                    "ca. " +
                                    (
                                      product.promotion.discountValue *
                                      product.weightInKg
                                    ).toFixed(2) +
                                    " kr/st"
                                  }}
                                </p>
                                <p v-else>
                                  {{
                                    "ca. " +
                                    (
                                      product.price * product.weightInKg
                                    ).toFixed(2) +
                                    " kr/st"
                                  }}
                                </p>
                              </div>
                              <p v-else class="body-4">
                                {{
                                  $t("comparison_price") +
                                  " " +
                                  product.comparePrice +
                                  " " +
                                  product.compareUnit
                                }}
                              </p>
                            </v-col>

                            <!-- Display the promotional price if it exists, otherwise display the regular price -->
                            <v-col
                              class="pa-0 mb-2"
                              cols="12"
                              justify="end"
                              align-self="end"
                            >
                              <div v-if="product.promotion">
                                <div v-if="product.promotion.minQuantity == 1">
                                  <p class="bold-1" style="color: #c94631">
                                    <span class="bold-h4">
                                      {{
                                        product.promotion.discountValue.toFixed(
                                          2
                                        )
                                      }}</span
                                    >
                                    {{
                                      "/" +
                                      (product.weightProduct
                                        ? "kg"
                                        : $t("piece_abbreviation"))
                                    }}
                                  </p>
                                  <p
                                    class="body-4"
                                    :style="{ textDecoration: 'line-through' }"
                                  >
                                    <span> {{ product.price }}</span>
                                    {{
                                      "/" +
                                      (product.weightProduct
                                        ? "kg"
                                        : $t("piece_abbreviation"))
                                    }}
                                  </p>
                                </div>
                                <p class="bold-1" v-else>
                                  <span class="font-weight-bold text-h6">
                                    {{ product.price }}</span
                                  >
                                  {{
                                    "/" +
                                    (product.weightProduct
                                      ? "kg"
                                      : $t("piece_abbreviation"))
                                  }}
                                </p>
                              </div>
                              <p class="bold-1" v-else>
                                <span class="font-weight-bold text-h6">
                                  {{ product.price }}</span
                                >
                                {{
                                  "/" +
                                  (product.weightProduct
                                    ? "kg"
                                    : $t("piece_abbreviation"))
                                }}
                              </p>
                            </v-col>
                          </v-row>

                          <v-btn
                            elevation="0"
                            color="primary"
                            block
                            @click="changeProduct(product)"
                            class="button text-none"
                            >Ändra</v-btn
                          >
                        </v-col>
                      </v-card>
                    </v-col></v-row
                  ></v-col
                ></v-row
              ></v-col
            >
          </v-col>
        </v-row></v-container
      >
    </v-card>
  </v-dialog>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import CategoryProductAPI from "@/api/CategoryProductAPI";
export default {
  name: "StaticSearchField",
  data() {
    return {
      search: "",
      isLoading: false,
      products: [],
      page: 1,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    originalProduct: {
      type: Object,
      default: {},
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    decline() {
      this.search = "";
      this.products = [];
      this.show = false;
    },
    onFocus() {
      this.search = "";
    },
    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          searchString: this.search,
          page: this.page,
        });
        this.products = [...this.products, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    handleProductClick(product) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: product._id },
      });
    },
    async handleSearch(event) {
      if (this.search.trim()) {
        await this.fetchProducts();

        event.target.blur();
      }
    },
    changeProduct(product) {
      this.$emit("changeProduct", {
        id: this.originalProduct._id,
        changedProduct: product,
      });
      this.decline();
    },
  },
};
</script>
<style>
.custom5cols {
  width: 20%;
  max-width: 200px;
  min-width: 170px;
  flex-basis: 20%;
}
</style>
