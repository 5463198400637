<template>
  <v-col class="pa-1">
    <!-- Custom search input and button to ensure matching height -->
    <v-text-field
      class="mt-0 rounded-circle"
      v-model="search"
      v-on:keyup.enter="handleSearch($event)"
      color="primary"
      variant="outlined"
      single-line
      hide-details
      rounded
      dense
      @focus="onFocus"
      bg-color="surface"
      prepend-inner-icon="mdi-magnify"
      :label="$t('search_item') + '...'"
    ></v-text-field>
  </v-col>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "StaticSearchField",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapFields(["searchTerm"]),
  },
  methods: {
    onFocus() {
      this.search = "";
    },
    handleSearch(event) {
      if (this.search.trim()) {
        this.searchTerm = this.search;
        this.search = "";

        this.$router.push({
          name: "search",
          query: {
            q: this.searchTerm.trim(),
          },
        });
        event.target.blur();
      }
    },
  },
};
</script>

<style scoped>
.search-field-wrapper {
  width: 100%;
  margin: auto; /* Center the search field horizontally */
  margin-bottom: 40px;
}

.search-label {
  display: block;
  margin-bottom: 8px;
  color: black; /* Optional: Add your text color */
  font-size: 16px;
}

.search-field-container {
  display: flex;
  width: 100%;
}

.custom-search-input {
  flex-grow: 1; /* Allow the input to take up all available space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px; /* Rounded corners for the left side */
  font-size: 16px;
  box-sizing: border-box;
  background-color: white;
}

.custom-search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 0 5px 5px 0; /* Rounded corners for the right side */
  background-color: #003a70; /* Optional: Add your button color */
  color: white;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  height: 100%; /* Ensure the button matches the input height */
}

.custom-search-button:hover {
  background-color: #005092;
}

/* Comment explaining the choice of custom inputs and buttons */
/*
We are using custom HTML inputs and buttons instead of Vue components to ensure that the input and button heights match perfectly. This decision was made because we encountered difficulties in matching the heights of Vuetify components despite multiple adjustments. By using custom HTML elements, we have full control over the styling, ensuring a consistent and cohesive design.
*/
</style>
