<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="700pt"
    height="593pt"
    viewBox="0 0 700.000000 593.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,593.000000) scale(0.100000,-0.100000)"
      fill="#FFFF00"
      stroke="none"
    >
<path d="M4216 5405 c-354 -441 -399 -495 -410 -478 -7 10 -122 215 -256 455
-134 241 -247 435 -251 430 -3 -4 -91 -277 -194 -607 -103 -330 -190 -603
-194 -607 -3 -4 -159 233 -346 526 -187 293 -341 532 -343 531 -1 -1 -22 -316
-47 -700 -25 -384 -46 -700 -48 -701 -2 -2 -248 143 -547 321 -300 178 -546
321 -548 316 -2 -5 67 -267 152 -582 86 -315 154 -574 152 -576 -3 -3 -1123
535 -1275 611 -29 15 -49 21 -45 14 6 -9 774 -888 1105 -1263 17 -20 6 -31
-522 -540 -296 -286 -533 -521 -526 -522 18 -4 1322 -83 1363 -83 19 0 34 -4
34 -8 0 -4 -142 -175 -315 -380 -173 -205 -311 -372 -305 -372 5 0 221 70 481
155 259 85 475 155 480 155 5 0 10 -129 10 -292 1 -161 5 -438 8 -616 l6 -323
484 790 c267 434 487 788 490 786 4 -2 94 -298 201 -658 107 -361 198 -653
201 -649 4 4 159 289 344 634 186 345 342 625 346 622 5 -3 209 -230 454 -505
245 -275 445 -493 445 -485 0 8 -32 294 -70 637 -39 343 -69 626 -68 630 2 4
275 -153 608 -348 333 -195 610 -357 616 -360 7 -2 -75 260 -182 583 -107 323
-192 589 -190 592 4 3 1321 -162 1371 -173 15 -3 11 4 -15 27 -136 123 -1050
981 -1050 986 0 4 242 128 538 276 295 149 530 271 521 273 -9 2 -271 37 -582
77 -312 39 -569 75 -572 77 -3 3 76 311 175 684 100 373 179 681 178 685 -2 4
-273 -235 -602 -531 -330 -296 -602 -536 -605 -533 -3 3 -62 345 -131 760
l-125 755 -399 -496z"/>
    </g>
    <!-- Text element in the middle -->
    <text
      x="50%"
      y="50%"
      dominant-baseline="middle"
      text-anchor="middle"
      :style="textStyle"
    >
      <slot>{{ text }} Kr</slot>
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    textStyle: {
      type: Object,
      default: () => ({
        fontSize: '120px', // Default font size
        fill: '#C94631',    // Default text color
        // Add more default styles if needed
      }),
    },
  },
};
</script>
