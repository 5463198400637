<template>
  <v-container fluid>
    <v-row justify="center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-col cols="12" v-else>
        <div>
          <h1 class="bold-h1">{{ $t("saved") }}</h1>
          <v-row class="pa-2 mt-4" align="center">
            <p class="bold-h3">{{ $t("my_lists") }}</p>
          </v-row>

          <v-row
            justify="center"
            align="center"
            class="pa-0 mt-4"
            v-if="createdLists.length > 0"
          >
            <v-col cols="12" lg="10" xl="10" class="pa-0">
              <v-row justify="center" align="center" class="pa-0">
                <v-col
                  class="custom4cols"
                  v-for="list in createdLists"
                  :key="list._id"
                >
                  <ListCard
                    :key="list._id"
                    :list="list"
                  /> </v-col></v-row></v-col
          ></v-row>
          <p v-else>{{ $t("no_results") }}</p>
          <p class="bold-h3 mt-4">{{ $t("liked_recipes") }}</p>
          <v-row
            justify="center"
            align="center"
            class="pa-0 mt-4"
            v-if="likedRecipes.length > 0"
          >
            <v-col cols="12" lg="10" xl="10" class="pa-0">
              <v-row justify="center" align="center" class="pa-0">
                <v-col
                  class="custom4cols"
                  v-for="recipe in likedRecipes"
                  :key="recipe._id"
                >
                  <v-card style="height: 325px" elevation="0"
                    ><v-col class="pa-0">
                      <v-col
                        align="center"
                        justify="center"
                        class="pa-0"
                        style="position: relative"
                      >
                        <div
                          style="
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            z-index: 1000;
                          "
                        >
                          <LikedButton :recipe="recipe" />
                        </div>
                        <v-row
                          style="
                            position: absolute;
                            bottom: 3px;
                            right: 0px;
                            z-index: 1000;
                          "
                          class="pa-4"
                          ><v-card
                            elevation="0"
                            class="pa-1 rounded-lg"
                            :color="getColor(recipe.difficulty)"
                            style="padding: 1px"
                            ><p class="pb-1 bold-2">
                              {{ recipe.difficulty }}
                            </p></v-card
                          ><v-card class="ml-4 pa-1 rounded-lg" elevation="0"
                            ><p class="pb-1 bold-2">
                              {{ recipe.time + " min" }}
                            </p></v-card
                          ></v-row
                        >
                        <v-img
                          v-if="recipe.image"
                          :src="recipe.image.url"
                          @click="goToRecipe(recipe)"
                          width="100%"
                          cover
                          height="250"
                          class="hover"
                          alt="Recipe Image"
                        />
                      </v-col>

                      <v-col align="center">
                        <p class="bold-h4">
                          {{ recipe.name }}
                        </p>
                      </v-col>
                    </v-col></v-card
                  >
                </v-col></v-row
              ></v-col
            ></v-row
          >
          <p v-else>{{ $t("no_results") }}</p>
          <v-row class="pa-2 mt-10" align="center">
            <p class="bold-h3">{{ $t("created_recipes") }}</p>
            <v-btn
              v-if="createdRecipes.length > 0"
              color="primary"
              flat
              class="button text-none mt-2 ml-6"
              @click="$router.push({ name: 'createRecipe' })"
              >{{ $t("create_recipe") }}</v-btn
            ></v-row
          >

          <v-row
            justify="center"
            align="center"
            class="pa-0 mt-8 mb-8"
            v-if="createdRecipes.length > 0"
          >
            <v-col cols="12" lg="10" xl="10" class="pa-0">
              <v-row justify="center" align="center" class="pa-0">
                <v-col
                  class="custom4cols"
                  v-for="recipe in createdRecipes"
                  :key="recipe._id"
                >
                  <v-card style="height: 325px" elevation="0"
                    ><v-col class="pa-0">
                      <v-col
                        align="center"
                        justify="center"
                        class="pa-0"
                        style="position: relative"
                      >
                        <div
                          style="
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            z-index: 1000;
                          "
                        >
                          <LikedButton :recipe="recipe" />
                        </div>
                        <v-row
                          style="
                            position: absolute;
                            bottom: 3px;
                            right: 0px;
                            z-index: 1000;
                          "
                          class="pa-4"
                          ><v-card
                            elevation="0"
                            class="pa-1 rounded-lg"
                            :color="getColor(recipe.difficulty)"
                            style="padding: 1px"
                            ><p class="pb-1 bold-2">
                              {{ recipe.difficulty }}
                            </p></v-card
                          ><v-card class="ml-4 pa-1 rounded-lg" elevation="0"
                            ><p class="pb-1 bold-2">
                              {{ recipe.time + " min" }}
                            </p></v-card
                          ></v-row
                        >
                        <v-img
                          v-if="recipe.image"
                          :src="recipe.image.url"
                          @click="goToRecipe(recipe)"
                          width="100%"
                          cover
                          height="250"
                          class="hover"
                          alt="Recipe Image"
                        />
                      </v-col>

                      <v-col align="center">
                        <p class="bold-h4">
                          {{ recipe.name }}
                        </p>
                      </v-col>
                    </v-col></v-card
                  >
                </v-col></v-row
              ></v-col
            ></v-row
          >
          <v-col v-else
            ><p class="bold-h4">{{ $t("no_created_recipes") }}</p>
            <p class="body-2 mt-2">{{ $t("create_recipe_prompt") }}</p>
            <v-btn
              color="primary"
              flat
              class="button text-none mt-2"
              @click="$router.push({ name: 'createRecipe' })"
              >{{ $t("create_recipe") }}</v-btn
            >
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/Header.vue";
import ListRow from "@/components/ListRow.vue";
import ShoppingListAPI from "@/api/SaveAPI.js";
import ListCard from "@/components/ListCard.vue";
import { useHead } from "@unhead/vue";
import RecipeAPI from "@/api/RecipeAPI";
export default {
  name: "SavedListsPage",
  components: {
    Header,
    ListRow,
    ListCard,
  },
  data() {
    return {
      createdRecipes: [],
      createdLists: [],
      likedRecipes: [],
      loading: true,
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
    async loadCreatedLists() {
      try {
        const response = await ShoppingListAPI.getUserOwnShoppingLists();
        this.createdLists = response.data.map((list) => ({
          ...list,
          products: list.activeProducts || [],
        }));
      } catch (error) {
        console.error("Error loading created lists:", error);
      } finally {
        this.loadingCreated = false;
      }
    },
    async loadLikedRecipes() {
      try {
        this.likedRecipes = await ShoppingListAPI.getUserLikedRecipes();
      } catch (error) {
        console.error("Error loading liked lists:", error);
      } finally {
        this.loadingLiked = false;
      }
    },
    async loadCreatedRecipes() {
      try {
        const resp = await RecipeAPI.getCreatedRecipe();
        this.createdRecipes = resp.data;
      } catch (error) {
        console.error("Error loading liked lists:", error);
      } finally {
        this.loadingLiked = false;
      }
    },
    goToRecipe(recipe) {
      this.$router.push({
        name: "recipeDetail",
        params: {
          id: recipe._id,
        },
      });
    },
    getColor(name) {
      switch (name) {
        case "Lätt":
          return "success";
        case "Medel":
          return "warning";
        case "Svårt":
          return "error";
        default:
          return "success";
      }
    },
  },
  async activated() {
    await this.loadCreatedLists();
    await this.loadLikedRecipes();
    await this.loadCreatedRecipes();
    useHead({
      title: "Sparat - NearbyStore",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "Sparat på NearbyStore",
        },
      ],
      __key: "savedHead",
    });
    this.loading = false;
  },
};
</script>

<style scoped>
.custom4cols {
  width: 25%;
  max-width: 250px;
  min-width: 200px;
  flex-basis: 20%;
}
.max-width-container {
  max-width: 1200px;
  margin-bottom: 142px;
}

.breadcrumbs {
  padding-left: 0;
  margin-bottom: 20px;
}

.saved-lists-page {
  padding: 20px;
  margin-top: 142px;
}

.page-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.modal-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
}

.modal-btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-btn.create {
  background-color: #003a70;
  color: white;
}

.modal-btn.create:hover {
  background-color: #005092;
}

.modal-btn.cancel {
  background-color: #c94631;
  color: white;
}

.modal-btn.cancel:hover {
  background-color: darkred;
}
</style>
