import { createI18n } from "vue-i18n";
import en from "../lang/en/en.json";
import sv from "../lang/sv/sv.json";
import fr from "../lang/fr/fr.json";

import sp from "../lang/sp/sp.json";
import ar from "../lang/ar/ar.json";

const i18n = createI18n({
  legacy: false, // Add this line
  globalInjection: true,
  locale: localStorage.getItem("lang") || "sv", // Set the initial language based on local storage or fallback to English
  fallbackLocale: "sv", // Fallback language if translation is missing
  messages: {
    en,
    sv,
    fr,
    sp,
    ar,
  },
  datetimeFormats: {
    "en-US": {
      short: {
        month: "short",
        day: "numeric",
      },
      long: {
        weekday: "long",
        month: "short",
        day: "numeric",
      },
    },
    sv: {
      short: {
        month: "short",
        day: "numeric",
      },
      long: {
        weekday: "long",
        month: "short",
        day: "numeric",
      },
    },
    sp: {
      short: {
        month: "short",
        day: "numeric",
      },
      long: {
        weekday: "long",
        month: "short",
        day: "numeric",
      },
    },
    fr: {
      short: {
        month: "short",
        day: "numeric",
      },
      long: {
        weekday: "long",
        month: "short",
        day: "numeric",
      },
    },
    ar: {
      short: {
        month: "short",
        day: "numeric",
      },
      long: {
        weekday: "long",
        month: "short",
        day: "numeric",
      },
    },
  },
});

export default i18n;
