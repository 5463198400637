<template>
  <v-app>
    <Header />
    <v-main class="v-main">
      <v-container fluid class="pa-0">
        <CategoryNavigation fullscreen class="category-navigation" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// Component imports
import Header from '@/components/Header.vue';
import CategoryNavigation from '@/components/CategoryNavigation.vue';

export default {
  name: 'CategoryNavigationScreen',
  components: {
    Header,
    CategoryNavigation,
  }
};
</script>

<style scoped>

.category-navigation {
top: 142px;
}

.v-main {
  background-color: white;
}

.pa-0 {
  padding: 0 !important;
}

/* Styling for the fullscreen CategoryNavigation */
.fullscreen-sidebar {
  width: 100% !important;
  height: 100vh;
}
</style>

