<template>
  <div class="add-new-list-card" @click="showCreateListModal">
    <v-icon v-if="iconType === 'magnify'" class="add-icon">mdi-magnify</v-icon>
    <div v-else class="add-icon">+</div>
  </div>
</template>

<script>
export default {
  name: "AddNewListCard",
  props: {
    iconType: {
      type: String,
      default: "plus" // Default to plus icon
    }
  },
  methods: {
    /**
     * Emits an event to show the create list modal.
     */
    showCreateListModal() {
      this.$emit('show-create-list-modal');
    },
  },
};
</script>

<style scoped>
.add-new-list-card {
  min-width: calc(25% - 20px);
  height: 100%;
  padding: 10px;
  border: 2px dashed darkgrey;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.add-new-list-card:hover {
  transform: scale(1.05);
}

.add-icon {
  font-size: 48px;
  color: darkgrey;
}


/* Responsive Layout */
@media (max-width: 1200px) {
  .add-new-list-card {
    min-width: calc(33.33% - 20px);
  }
}

@media (max-width: 992px) {
  .add-new-list-card {
    min-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .add-new-list-card {
    min-width: calc(100% - 20px);
  }

  }

</style>

