// CategoryProductAPI.js
import axiosInstance from "./AxiosInstance";

/**
 * API for interacting with category-related endpoints.
 */
const CategoryProductAPI = {
  /**
   * Retrieves all categories.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the categories.
   */
  getAllCategories: () => {
    return axiosInstance.get("/categoryVariant");
  },

  /**
   * Retrieves a Product.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the categories.
   */
  getProduct: (id) => {
    return axiosInstance.get("/product/" + id);
  },

  /**
   * Retrieves products based on specified query parameters.
   *
   * @param {number} [limit=50] - The maximum number of products to return.
   * @param {number} [page=1] - The page number for pagination.
   * @param {string} [category=""] - The category ID of the product.
   * @param {string} [searchString=""] - Search query string for products.
   * @param {string} [tag=""] - Specific tag associated with the product.
   * @param {boolean} [hidden=false] - Whether to include hidden products.
   * @param {boolean} [inStore=true] - Whether to only return products available in store.
   * @param {boolean} [isDrug=false] - Whether the product is a drug.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the products.
   */
  getProducts: ({
    limit = 50,
    page = 1,
    category = "",
    searchString = "",
    tag = "",
    hidden = false,
    inStore = true,
    isDrug = false,
    offers = false,
  } = {}) => {
    const params = {
      limit,
      page,
      category,
      searchString,
      tag,
      hidden,
      inStore,
      isDrug,
      offers,
    };

    return axiosInstance.get("/product", { params });
  },
};

export default CategoryProductAPI;
