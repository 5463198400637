<template>
  <v-card
    class="rounded-circle"
    color="yellow"
    flat
    style="border: 6px solid red"
    width="45"
    height="45"
  >
    <v-col class="pa-0" align="center" justify="center"
      ><p class="pa-2 bold-age">{{ minAge }}</p></v-col
    >
  </v-card>
</template>

<script>
export default {
  name: "StarWithText",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    minAge: {
      type: Number,
      default: 18,
    },
    size: {
      type: Object,
      default: () => ({ width: 50, height: 50 }),
    },
    fillColor: {
      type: String,
      default: "#ffe400", // Amber color from Flutter example
    },

    textColor: {
      type: String,
      default: "#ce003c", // Default text color
    },
  },

  computed: {
    points() {
      const spikes = 16;
      const outerRadius = 50; // Half of the SVG viewBox size
      const innerRadius = outerRadius * 0.8;
      const centerX = 50; // Center of the SVG viewBox
      const centerY = 50;
      let points = [];

      for (let i = 0; i < spikes; i++) {
        const angle = (i * 2 * Math.PI) / spikes;
        const nextAngle = ((i + 1) * 2 * Math.PI) / spikes;

        const outerX = centerX + outerRadius * Math.cos(angle);
        const outerY = centerY + outerRadius * Math.sin(angle);

        const innerX =
          centerX + innerRadius * Math.cos((angle + nextAngle) / 2);
        const innerY =
          centerY + innerRadius * Math.sin((angle + nextAngle) / 2);

        points.push(`${outerX},${outerY}`);
        points.push(`${innerX},${innerY}`);
      }

      return points.join(" ");
    },
  },
};
</script>

<style scoped>
.bold-age {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 10pt;
  font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
}
/* Add any styles you need here */
</style>
